<template>
  <div class="body">
    <CommonHeader />
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-5 justify-content-center mx-auto">
          <div class="login_container ps-2 pe-2 ps-lg-3 pe-lg-3 ps-xl-5 pe-xl-5">
            <p class="login_heading mt-3">
              Log in to LITTA <span class="partners_color">PARTNERS</span>
              <span class="tm">TM</span>
            </p>

            <div class="mt-4 form-group">
              <div class="mb-3" :class="{ 'has-error': emailError }">
                <div class="input_wrapper">
                  <input
                    type="email"
                    class="form-control auth_input"
                    placeholder="Email"
                    @keyup.enter="performLogin"
                    v-model="email"
                    @input="clearErrors('email')"
                  />
                  <i class="bi bi-person input_icon"></i>
                </div>
                <p class="error-message text-left ps-3" v-if="emailError">
                  {{ emailError }}
                </p>
              </div>

              <div class="mb-3" :class="{ 'has-error': passwordError }">
                <div class="input_wrapper">
                  <input
                    class="form-control auth_input password_input"
                    placeholder="Password"
                    @keyup.enter="performLogin"
                    v-model="password"
                    :type="showPassword ? 'text' : 'password'"
                    @input="clearErrors('password')"
                  />
                  <i class="bi bi-lock input_icon"></i>
                  <i class="bi bi-eye eye_icon" @click="togglePasswordVisibility"></i>
                </div>
                <p class="error-message text-left ps-3" v-if="passwordError">
                  {{ passwordError }}
                </p>
              </div>

              <router-link class="navbar-brand mb-3" to="/partner/forgot-password">
                <p class="forgot_password">Forgot Password?</p>
              </router-link>

              <button class="login_button mb-5" @click="performLogin()">
                Continue with Email
              </button>

              <!-- <div class="mb-3">
                                <p class="dont_account">
                                    Don't have a LITTA<span>TM</span> account?
                                </p>
                            </div>

                            <div class="mb-3">
                                <router-link class="navbar-brand mb-3" to="/partner/signup">
                                    <button class="signup_button">
                                        Sign Up
                                    </button>
                                </router-link>
                            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonHeader from "@/components/AuthHeader/PartnerHeader.vue";
import { auth } from "@/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useMyStore } from "@/store";
import axios from "axios";
export default {
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      emailError: "",
      passwordError: "",
      myStore: useMyStore(),
    };
  },
  components: {
    CommonHeader,
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword; // Toggle password visibility
    },
    clearErrors(field) {
      if (field === "email") {
        this.emailError = "";
      } else if (field === "password") {
        this.passwordError = "";
      }
    },
    async performLogin() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.email) {
        this.emailError = "Please fill email field";
        return;
      }
      if (!emailRegex.test(this.email)) {
        this.emailError = "Please enter a valid email address";
        return;
      }
      if (!this.password) {
        this.passwordError = "Please fill password field";
        return;
      }

      this.myStore.valid_user = null;
      this.myStore.partner_valid_user = null;
      this.myStore.commercial_permissions = null;

      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          this.email,
          this.password
        );
        const user = userCredential.user;
        console.log("Logged in user:", user);
        this.performLastLoginandSession(user);
        localStorage.setItem("commercials_user_data", JSON.stringify(user));
        this.$router.push("/partner/dashboard");
        this.myStore.fetchPartnerCompanyName();
      } catch (error) {
        console.error("Error logging in:", error.message);
        this.emailError = "Incorrect email or password";
        this.passwordError = "Incorrect email or password";
      }
    },
    async performLastLoginandSession(loggedinUser) {
      const data = {
        lastLogin: new Date(Number(loggedinUser.reloadUserInfo.lastLoginAt)),
        lastSession: loggedinUser.reloadUserInfo.lastRefreshAt,
        userId: loggedinUser.uid,
      };
      try {
        const lastActivity = await axios.post(
          `https://portal-litta-api.web.app/partners/users/${loggedinUser.uid}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Last activity:", lastActivity);
      } catch (error) {
        console.log("ERROR", error);
      }
    },
  },
};
</script>

<style scoped>
.body {
  background-color: #f7fafc;
  min-height: 100vh;
}

.login_container {
  border: 1px solid #7b37ef;
  text-align: center;
  border-radius: 10px;
}

.login_heading {
  font-size: 24px;
  font-weight: 700;
  vertical-align: top;
}

.login_heading .tm {
  font-size: 10px;
  vertical-align: top;
  position: relative;
  top: 5px;
}

.auth_input {
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 50px;
}

.input_wrapper {
  position: relative;
}

.input_wrapper input {
  padding-left: 30px; /* Space for the icon */
}

.input_icon {
  position: absolute;
  left: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
}

.eye_icon {
  position: absolute;
  right: 20px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.password_input {
  padding-right: 40px;
}

.forgot_password {
  color: #000;
  font-size: 18px;
  text-decoration: underline;
  text-align: right;
  cursor: pointer;
}

.login_button {
  background-color: #7b37ef;
  color: #fff;
  width: 100%;
  border-radius: 15px;
  padding: 10px 10px;
  border: none;
}

.google_login {
  background: rgb(66 133 244);
  width: 100%;
  padding: 5px 5px;
  color: #fff;
  border-radius: 50px;
  border: none;
}

.google_login img {
  float: left;
}

.apple_login {
  background: #fff;
  width: 100%;
  padding: 5px 5px;
  color: #000;
  border-radius: 50px;
  border: 1px solid #000;
}

.dont_account {
  color: #000;
  font-size: 19px;
}

.dont_account span {
  font-size: 10px;
  vertical-align: top;
  position: relative;
  top: 5px;
}

.signup_button {
  color: #7b37ef;
  border: 2px solid #7b37ef;
  width: 100%;
  background: transparent;
  padding: 5px 10px;
  border-radius: 15px;
}

.partners_color {
  color: #7b37ef;
}

.has-error input {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 12px;
  text-align: left;
  margin-top: 5px;
}
</style>
