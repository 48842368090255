<template>
    <LoadingOverlay :active="isLoading" :can-cancel="false" :is-full-page="true" />
    <div class="body container-fluid">
        <div class="headerr p-3 p-xl-5 pb-3 pb-xl-3">
            <CommonHeader />
        </div>

        <BookingForm />
    </div>
    <CommonFooter />
</template>

<script>
    import CommonFooter from '@/components/HeaderFooter/CommonFooter.vue';
    import LoadingOverlay from 'vue3-loading-overlay';
    import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
    import CommonHeader from '@/components/HeaderFooter/CommonHeader.vue';
    import BookingForm from '@/components/DashboardTabs/BookingForm.vue';
    import { useMyStore } from '@/store';
    // import axios from 'axios';
    export default {
        data() {
            return {
                isLoading: false,
                myStore: useMyStore(),
            }
        },
        components: {
            LoadingOverlay,
            CommonHeader,
            CommonFooter,
            BookingForm,
        },
        mounted() {

        }
    }
</script>

<style scoped>
    .body{
      background-color: #F7FAFC;
      min-height: 90vh;
    }
</style>