<template>
    <!-- <div class="p-0 p-xl-5 pt-0 pt-xl-0"> -->
    <div v-if="!noDataFound">
        <div class="row mb-3">
            <div class="col-12 col-xl">
                <p class="p-0 p-xl-5 pt-0 pb-0 pb-xl-0 pt-xl-0 main_text ps-1">
                    {{ subTitle }}
                </p>
            </div>

            <div class="col-12 col-xl-auto d-flex">
                <button class="btn btn-custom me-2" type="button" data-bs-toggle="modal" data-bs-target="#jobsSort">
                    <i class="bi bi-sort-down"></i> <b>Sort</b>
                </button>

                <div class="modal fade" id="jobsSort" tabindex="-1" aria-labelledby="jobsSortLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="jobsSortLabel">Sort Options</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group mb-3">
                                <label>
                                    Select Column
                                </label>
                                <select class="form-select" v-model="selectedSortColumn">
                                    <option :value="option" v-for="(option,index) in sortColumnsCollection" :key="index">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <hr>
                            <div class="form-group mb-3">
                                <label>
                                    Select Order
                                </label>
                                <select class="form-select" v-model="sort_order">
                                    <option value="ASC">ASC</option>
                                    <option value="DESC">DESC</option>
                                </select>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="clearSort()">Clear Sort</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" @click="performSort()" data-bs-dismiss="modal">Apply Sort</button>
                        </div>
                        </div>
                    </div>
                </div>

                <button class="btn btn-custom" type="button" data-bs-toggle="modal" data-bs-target="#jobsFilter">
                    <i class="bi bi-funnel-fill me-1"></i> <b>Filter</b>
                </button>

                <div class="modal fade" id="jobsFilter" tabindex="-1" aria-labelledby="jobsFilterLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="jobsFilterLabel">Filter Options</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group mb-3">
                                <label>Driver Licence Status</label>
                                <select class="form-select" v-model="selectedStatusLicence">
                                    <option value=""> - Select Status - </option>
                                    <option :value="option" v-for="(option,index) in statusCollectionLicence" :key="index">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <hr>
                            <div class="form-group mb-3">
                                <label>Partner Status</label>
                                <select class="form-select" v-model="selectedStatusPartner">
                                    <option value=""> - Select Status - </option>
                                    <option :value="option" v-for="(option,index) in statusCollectionPartner" :key="index">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <!-- <div class="form-group mb-3">
                                <label>
                                    From Date:
                                </label>
                                <input type="date" class="form-control" v-model="selectedFrom">
                            </div>

                            <div class="form-group mb-3">
                                <label>
                                    To Date:
                                </label>
                                <input type="date" class="form-control" v-model="selectedTo">
                            </div> -->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="clearFilter()">Clear Filter</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" @click="performFilter()" data-bs-dismiss="modal">Apply Filter</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-none d-xl-block">
            <div class="table-container">
                <div class="table-responsive tableFixHead">
                    <table class="table">
                        <thead>
                            <tr>
                                <template v-for="(field, index) in tableFields" :key="index">
                                    <th v-if="field.type != 'hover'" scope="col">{{ field.title }}</th>
                                </template>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in tableData" :key="index">
                                <template v-for="(field, idx) in row" :key="idx">
                                    <td v-if="field.type != 'hover'" >
                                        <template v-if="tooltipExist(row,field)">
                                            <p class="cursor_pointer" v-if="field?.type == 'text'" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                {{ field.value == null ? '-' : addCommas(field.value) }}
                                            </p>
                                            <div v-if="field.type == 'bubble'" class="dynamic_bubble cursor_pointer" :style="`background: ${field.bubbleColour};`" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                {{ field.value }}
                                            </div>
                                            <a class="cursor_pointer" :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                <button class="btn btn-secondary w-100 text-center">
                                                    Document
                                                </button>
                                            </a>
                                            <a class="cursor_pointer" :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                <button class="btn btn-primary w-100 text-center">
                                                    Document
                                                </button>
                                            </a>
                                            <img v-if="field.type == 'image'" :src="field.value" class="table_image" alt="" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                        </template>
                                        <template v-else>
                                            <span v-if="field?.type == 'text'">
                                                {{ field.value == null ? '-' : field.fieldName == 'fleet_id' ? field.value : addCommas(field.value) }}
                                            </span>
                                            <div v-if="field.type == 'bubble'" class="dynamic_bubble" :style="`background: ${field.bubbleColour};`">
                                                {{ field.value }}
                                            </div>
                                            <a :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null">
                                                <button class="btn btn-secondary w-100 text-center">
                                                    Document
                                                </button>
                                            </a>
                                            <a :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank">
                                                <button class="btn btn-primary w-100 text-center">
                                                    Document
                                                </button>
                                            </a>
                                            <button v-if="field.type == 'array'" class="btn btn-primary" @click="toggleImages(field.value)" data-bs-toggle="modal" data-bs-target="#imagesPopup">
                                                Show Images
                                            </button>
                                            <img v-if="field.type == 'image'" :src="field.value" class="table_image" alt="">
                                        </template>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                        <tfoot class="d-none">
                            <tr>
                                <template v-for="(field, index) in tableFields" :key="index">
                                    <th v-if="field.type != 'hover'"  scope="col">
                                        <!-- <hr> -->
                                        {{ field.fieldName == 'payoutAmount' ? 'Total: ' + formatNumberInteger(sumAmount) : '' }}
                                    </th>
                                </template>
                                
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

        <div class="d-block d-xl-none mobile_cards_container">
            <template v-for="(row, index) in tableData" :key="index">
                <div class="card mb-3" @click="selectedTempJob = row" data-bs-toggle="modal" data-bs-target="#jobdatacard">
                    <div class="card-body">
                        <i class="bi bi-arrows-angle-expand expand_icon"></i>
                        <template v-for="(field, idx) in row" :key="idx">
                            <template v-if="idx < 3">
                                <b>
                                    <p>{{ field.title }}</p>
                                </b>
                                <p v-if="field.type != 'hover'" >
                                    <template v-if="tooltipExist(row,field)">
                                        <p class="cursor_pointer" v-if="field?.type == 'text'" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                            {{ field.value == null ? '-' : addCommas(field.value) }}
                                        </p>
                                        <div v-if="field.type == 'bubble'" class="dynamic_bubble cursor_pointer" :style="`background: ${field.bubbleColour};`" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                            {{ field.value }}
                                        </div>
                                        <a class="cursor_pointer" :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                            <button class="btn btn-secondary w-100 text-center">
                                                Document
                                            </button>
                                        </a>
                                        <a class="cursor_pointer" :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                            <button class="btn btn-primary w-100 text-center">
                                                Document
                                            </button>
                                        </a>
                                        <img v-if="field.type == 'image'" :src="field.value" class="table_image" alt="" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                    </template>
                                    <template v-else>
                                        <span v-if="field?.type == 'text'">
                                            {{ field.value == null ? '-' : field.fieldName == 'fleet_id' ? field.value : addCommas(field.value) }}
                                        </span>
                                        <div v-if="field.type == 'bubble'" class="dynamic_bubble" :style="`background: ${field.bubbleColour};`">
                                            {{ field.value }}
                                        </div>
                                        <a :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null">
                                            <button class="btn btn-secondary w-100 text-center">
                                                Document
                                            </button>
                                        </a>
                                        <a :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank">
                                            <button class="btn btn-primary w-100 text-center">
                                                Document
                                            </button>
                                        </a>
                                        <button v-if="field.type == 'array'" class="btn btn-primary" @click="toggleImages(field.value)" data-bs-toggle="modal" data-bs-target="#imagesPopup">
                                            Show Images
                                        </button>
                                        <img v-if="field.type == 'image'" :src="field.value" class="table_image" alt="">
                                    </template>
                                </p>
                            </template>
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>

    <div class="modal fade" id="jobdatacard" tabindex="-1" aria-labelledby="jobdatacardLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="jobdatacardLabel">Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <template v-for="(field, idx) in selectedTempJob" :key="idx">
                        <b>
                            <p>{{ field.title }}</p>
                        </b>
                        <p v-if="field.type != 'hover'" >
                            <template v-if="tooltipExist(selectedTempJob,field)">
                                <p class="cursor_pointer" v-if="field?.type == 'text'" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                                    {{ field.value == null ? '-' : addCommas(field.value) }}
                                </p>
                                <div v-if="field.type == 'bubble'" class="dynamic_bubble cursor_pointer" :style="`background: ${field.bubbleColour};`" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                                    {{ field.value }}
                                </div>
                                <a class="cursor_pointer" :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                                    <button class="btn btn-secondary w-100 text-center">
                                        Document
                                    </button>
                                </a>
                                <a class="cursor_pointer" :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                                    <button class="btn btn-primary w-100 text-center">
                                        Document
                                    </button>
                                </a>
                                <img v-if="field.type == 'image'" :src="field.value" class="table_image" alt="" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                            </template>
                            <template v-else>
                                <span v-if="field?.type == 'text'">
                                    {{ field.value == null ? '-' : field.fieldName == 'fleet_id' ? field.value : addCommas(field.value) }}
                                </span>
                                <div v-if="field.type == 'bubble'" class="dynamic_bubble" :style="`background: ${field.bubbleColour};`">
                                    {{ field.value }}
                                </div>
                                <a :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null">
                                    <button class="btn btn-secondary w-100 text-center">
                                        Document
                                    </button>
                                </a>
                                <a :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank">
                                    <button class="btn btn-primary w-100 text-center">
                                        Document
                                    </button>
                                </a>
                                <button v-if="field.type == 'array'" class="btn btn-primary" @click="toggleImages(field.value)" data-bs-toggle="modal" data-bs-target="#imagesPopup">
                                    Show Images
                                </button>
                                <img v-if="field.type == 'image'" :src="field.value" class="table_image" alt="">
                            </template>
                        </p>
                    </template>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="noDataFound" class="p-0 p-xl-5 pt-0 pt-xl-0">
        <div class="alert alert-warning">
            Oops something went wrong, we're working on fixing this
        </div>
    </div>
    
    <div class="modal fade" id="imagesPopup" tabindex="-1" aria-labelledby="imagesPopupLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="imagesPopupLabel">Images</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <Carousel v-if="showImages">
                    <Slide v-for="(slide,index) in imagesCollection" :key="index">
                        <img class="d-block w-100" :src="slide" alt="slide 1"/>
                        <!-- {{ slide }} -->
                    </Slide>

                    <template #addons>
                        <Navigation />
                        <Pagination />
                    </template>
                </Carousel>
                <!-- {{ imagesCollection }} -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useMyStore } from '@/store';
    import 'bootstrap/dist/js/bootstrap.bundle.min.js';
    import axios from 'axios';
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    export default{
        data() {
            return {
                collection: [],
                selectedTempJob: [],
                tableFields: [],
                tableData: [],
                subTitle: '',
                myStore: useMyStore(),
                statusCollectionLicence: [],
                statusCollectionPartner: [],
                selectedStatusLicence: '',
                selectedStatusPartner: '',
                selectedFrom: '',
                selectedTo: '',
                sumAmount: 0,
                sort_order: 'ASC',
                sortOptions: false,
                noDataFound: false,
                sortColumnsCollection: [
                    'Driver ID',
                    'Driver Name',
                    'User Name',
                ],
                selectedSortColumn: 'Driver ID',
                imagesCollection: [],
                showImages: false,
                breakpoints: {
                    700: {
                        itemsToShow: 1,
                        snapAlign: 'center',
                    },
                    // 1024 and up
                    1024: {
                        itemsToShow: 1,
                        snapAlign: 'start',
                    },
                },
            }
        },
        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
        },
        async mounted() {
            // await axios.get('https://portal-litta-api.web.app/commercial/content').then(response=>{
            //     response.data.data.map(item=>{
            //         if (item.type == 'tab' && item.tag == 'litta_compliance_documents') {
            //             this.subTitle = item.subtitle;
            //         }
            //     });
            // }).catch(error=>{
            //     console.log('error: ', error);
            // });
            this.myStore.showFooter = false;
            this.fetchData();

            this.myStore.partner_dashboard.data.data.map((item)=>{
                if (item.type?.includes('tab') && item.tag == 'drivers') {
                    this.subTitle = item.subtitle;
                }
            });
        },
        methods: {
            clearSort(){
                this.selectedSortColumn = 'Driver ID';
                this.sort_order = 'ASC';
                this.performSort();
            },
            toggleImages(val) {
                this.imagesCollection = [];
                this.showImages = false;
                this.imagesCollection = val;
                this.showImages = true;
                this.$forceUpdate();
                console.log(val);
            },
            performSort() {
                if (this.selectedSortColumn == 'Driver ID') {
                    // this.sort(this.sort_order);
                    this.sortByField('fleet_id');
                } else if (this.selectedSortColumn == 'Driver Name') {
                    this.sortByField('driver_name');
                } else if (this.selectedSortColumn == 'User Name') {
                    this.sortByField('username');
                }
            },
            sortByField(fieldName) {
                this.tableData.sort((a, b) => {
                    const valueA = this.getFieldValue(a, fieldName);
                    const valueB = this.getFieldValue(b, fieldName);

                    if (valueA === null || valueB === null) {
                        // Handle null values by placing them at the end
                        return valueA === null ? 1 : -1;
                    }

                    if (typeof valueA === 'string' && typeof valueB === 'string') {
                        // Sort alphabetically in descending order (from Z to A)
                        console.log('here', valueB.localeCompare(valueA));
                        return this.sort_order === 'ASC' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
                    }

                    if (this.sort_order === 'ASC') {
                        return valueA - valueB;
                    } else {
                        return valueB - valueA;
                    }
                });
            },
            getFieldValue(record, fieldName) {
                const field = record.find(item => item.fieldName === fieldName);
                return field ? field.value : null;
            },
            sort(val) {
                this.sort_order = val;
                this.sortOptions = false;

                this.tableData.sort((a, b) => {
                    const dateA = this.parseDateTime(a);
                    const dateB = this.parseDateTime(b);

                    if (this.sort_order === 'ASC') {
                        return dateA - dateB;
                    } else {
                        return dateB - dateA;
                    }
                });

                console.log("Dates of the first 10 records after sorting:");
            },

            parseDateTime(record) {
                const dateField = record.find(item => item.fieldName === 'completionDate');
                const timeField = record.find(item => item.fieldName === 'completionTime');

                if (!dateField || !timeField || !dateField.value || !timeField.value) {
                    // If either date or time value is missing, return a date far in the future
                    return this.sort_order === 'ASC' ? new Date('9999-12-31T23:59:59') : new Date('0000-01-01T00:00:00');
                }

                const dateValue = dateField.value;
                const timeValue = timeField.value;

                const [day, month, year] = dateValue.split('/');
                const [hours, minutes, seconds] = timeValue.split(':');

                return new Date(year, month - 1, day, hours, minutes, seconds);
            },
            async fetchData() {
                if (this.myStore.partnerDrivers == null) {
                    let user_details = localStorage.getItem('commercials_user_data');
                    user_details = JSON.parse(user_details);
                    console.log('user details: -> ', user_details);
                    let user_id = user_details.uid;
                    // await axios.get(`https://portal-litta-api.web.app/partners/users/SjYrg4yWs8XpbrBefcvMeNn7Od72/drivers`)
                    await axios.get(`https://portal-litta-api.web.app/partners/users/${user_id}/drivers`)
                    .then(response => {
                        // this.processData(response.data.data);
                        this.myStore.partnerDrivers = response;
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
                }
                if (this.myStore.partnerDrivers) {
                    this.processData(this.myStore.partnerDrivers?.data.data);
                    this.performSort();
                } else {
                    this.noDataFound = true;
                }
                this.$emit('triggerFunction');
            },
            processData(data) {
                this.tableFields = data[0].map(item => item);
                this.tableData = data.map(row => row.map(item => item));
                this.sort(this.sort_order);
                data.map((item)=>{
                    item.map((item2)=>{
                        if (item2.fieldName == 'driving_licence_status') {
                            if (!this.statusCollectionLicence.includes(item2.value)){
                                this.statusCollectionLicence.push(item2.value);
                            }
                        }
                        if (item2.fieldName == 'partner_status') {
                            if (!this.statusCollectionPartner.includes(item2.value)){
                                this.statusCollectionPartner.push(item2.value);
                            }
                        }
                    });
                });
                // this.sumAmount = 0;
                // data.map(item => {
                //     const tempAmount = item.find(item2 => item2.fieldName === 'payoutAmount');
                //     if (tempAmount.value != null) {
                //         this.sumAmount += tempAmount.value; 
                //     }
                // });
            },
            clearFilter() {
                this.selectedStatus = '';
                this.selectedFrom = '';
                this.selectedTo = '';
                this.processData(this.myStore.partnerDrivers.data.data);
                // this.sumAmount = 0;
                // this.myStore.partnerJobs.data.data.map(item => {
                //     const tempAmount = item.find(item2 => item2.fieldName === 'payoutAmount');
                //     if (tempAmount.value != null) {
                //         this.sumAmount += tempAmount.value; 
                //     }
                // });
                this.performSort();
            },
            performFilter(){
                let temp_data = [];
                if (this.selectedStatusLicence == '' && this.selectedStatusPartner == '') {
                    temp_data = this.myStore.partnerDrivers.data.data;
                } else if (this.selectedStatusLicence != '') {
                    this.myStore.partnerDrivers.data.data.map((item)=>{
                        item.map((item2)=>{
                            if (item2.fieldName == 'driving_licence_status' && this.selectedStatusLicence == item2.value) {
                                temp_data.push(item);
                            }
                        });
                    });
                } else if (this.selectedStatusPartner != '') {
                    this.myStore.partnerDrivers.data.data.map((item)=>{
                        item.map((item2)=>{
                            if (item2.fieldName == 'partner_status' && this.selectedStatusPartner == item2.value) {
                                temp_data.push(item);
                            }
                        });
                    });
                } 
                // if (this.selectedFrom != '' && this.selectedTo != '') {
                //     const fromDate = new Date(this.selectedFrom);
                //     const toDate = new Date(this.selectedTo);
                //     const filteredData = temp_data.filter(item => {
                //         const completionDate = item.find(item2 => item2.fieldName === 'completionDate');
                //         if (completionDate) {
                //             if (completionDate.value != null) {
                //                 const itemDate = this.parseDate(completionDate.value);
                //                 return itemDate >= fromDate && itemDate <= toDate;
                //             }
                //         }
                //         return false;
                //     });
                //     temp_data = filteredData;
                // }
                // this.sumAmount = 0;
                // temp_data.map(item => {
                //     const tempAmount = item.find(item2 => item2.fieldName === 'payoutAmount');
                //     if (tempAmount.value != null) {
                //         this.sumAmount += tempAmount.value; 
                //     }
                // });
                this.tableData = temp_data;
                // document.getElementById('jobsFilter').style.display = 'none';
                // const modalBackdrops = document.querySelectorAll('.modal-backdrop');
                // modalBackdrops.forEach(modalBackdrop => {
                //     modalBackdrop.remove();
                // });
                // console.log(temp_data);
                this.performSort();
            },
            parseDate(dateString) {
                const parts = dateString.split('/');
                return new Date(parts[2], parts[1] - 1, parts[0]);
            },
            toHumanReadable(fieldName) {
                return fieldName.split(/(?=[A-Z])/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            },
            formatNumber(val) {
                // Remove any non-numeric characters
                let value = val.toString().replace(/[^0-9.]/g, '');

                // Split the number into integer and decimal parts
                let parts = value.split('.');
                let integerPart = parts[0];
                let decimalPart = parts[1] || '';

                // Add commas to the integer part
                integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                // Limit the decimal part to 2 digits
                decimalPart = decimalPart.padEnd(2, '0').slice(0, 2);

                // Concatenate the integer and decimal parts with a decimal point
                value = integerPart + (decimalPart ? '.' + decimalPart : '');

                // Update the formatted value
                return value;
            },
            formatNumberInteger(val) {
                // Remove any non-numeric characters
                let value = val.toString().replace(/[^0-9.]/g, '');
                let parts = value.split('.');
                let integerPart = parts[0];
                // Add commas to the integer part
                integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                return integerPart;
            },
            addCommas(value) {
                if (!isNaN(value) && typeof value === 'number') {
                    return value.toLocaleString();
                } else {
                    return value;
                }
            },
            checkStatus(row) {
                let temp_data = row.find(item=>item.fieldName == 'status');
                if (temp_data.value == 'Cancelled') {
                    return true;
                } else {
                    false;
                }
            },
            tooltipExist(row,field){
                let temp_condition = false; 
                row.map((item)=>{
                    if (item.type == 'hover' && item.onHover == field.fieldName) {
                        temp_condition = true;
                    }
                });
                return temp_condition;
            },
            getTooltipValue(row,field) {
                let temp_condition = 'Testing'; 
                row.map((item)=>{
                    if (item.type == 'hover' && item.onHover == field.fieldName) {
                        temp_condition = item.value;
                    }
                });
                return temp_condition;
            },
        }
    }
</script>

<style scoped>
    .table_header{
        background: #1E7F78;
        color: #fff;
    }

    .main_text{
        font-family: poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.68px;
        text-align: left;
    }
    

    .table_header p{
        font-size: 20px;
        font-family: poppins;
        font-weight: 800;
        margin-bottom: 0;
    }

    .table_header span{
        align-items: center;
    }

    .align_contents_center{
        align-content: center;
        align-items: center;
    }

    .btn-white{
        background-color: #fff;
    }

    .company_name{
        background-color: #E9EEF9;
        color: #000;
        padding: 5px 20px;
        width: fit-content;
        border-radius: 10PX;
    }

    table { border-collapse: collapse; width: 100%; }
    th, td { background: #fff; padding: 8px 16px; }


    .tableFixHead {
        overflow: auto;
        height: 70vh;
    }

    .tableFixHead thead th {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #eeeeee;
    }

    .tableFixHead tfoot th {
        position: sticky;
        bottom: -5px;
        z-index: 1; /* Ensure the footer is on top of other content */
        background-color: #eeeeee;
    }

    th {
        white-space: nowrap;
    }

    i.text-success{
        font-weight: 900;
        font-size: 24px;
    }

    .dynamic_bubble{
        color: #fff;
        padding: 5px 10px;
        text-align:center;
        border-radius: 10px;
    }

    .btn-custom{
        background-color: #fff;
        border: 1px solid #000;
    }

    .cursor_pointer{
        cursor: pointer;
    }

    .table_image{
        width: 50px;
    }

    .mobile_cards_container{
        overflow: auto;
        height: 70vh;
    }

    .mobile_cards_container .card-body{
        position: relative;
    }

    .mobile_cards_container .expand_icon{
        position: absolute;
        right: 10px;
        top: 10px;
    }

    b p{
        margin-bottom: 0;
    }

    @media(max-width:764px){
        .table_header p{
            font-size: 16px;
        }
    }
</style>