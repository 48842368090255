<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid p-3 p-xl-5 pb-3 pb-xl-3 pt-3 pt-xl-3">
                <router-link class="navbar-brand" to="/">
                    <img src="@/assets/website_logo.svg" class="website_logo" alt="">
                </router-link>
                <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item me-lg-3">
                        <router-link class="navbar-brand" to="/commercial/login">
                            <button class="login_button w-100 mt-2 mt-lg-0 ">
                                Login
                            </button>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="navbar-brand" to="/commercial/signup">
                            <button class="signup_button w-100 mt-2 mt-lg-0">
                                Signup
                            </button>
                        </router-link>
                    </li>
                </ul> -->
                <!-- </div> -->
            </div>
        </nav>
    </div>
</template>

<script>
    export default{
        data() {
            return {

            }
        }, 
        mounted() {

        },
    }
</script>

<style scoped>
    .login_button{
        border: 1px solid rgb(26, 26, 26);
        background-color: transparent;
        padding: 5px 15px;
        border-radius: 10px;
        font-size: 18px;
        font-weight: 300;
    }

    .login_button:hover{
        background-color: rgb(232 235 239);
    }

    .signup_button{
        background: rgb(218 25 105);
        border: 1px solid rgb(232 235 239);
        padding: 5px 15px;
        border-radius: 10px;
        font-size: 18px;
        font-weight: 300;
        color: #fff;
    }

    .nav-item{
        /* text-align: right; */
    }
</style>