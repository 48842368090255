<template>
    <LoadingOverlay :active="isLoading" :can-cancel="false" :is-full-page="true" />
    <div class="body container-fluid">
      <div class="headerr p-3 p-xl-5 pb-3 pb-xl-3">
        <CommonHeader />
  
        <div class="row mt-5 ">
            <div class="col-auto">
              <div class="d-block d-lg-none" @click="scrollToPrevTab">
                  <i class="bi bi-caret-left-fill"></i>
              </div>
            </div>
            <div class="class_css_tab_headers d-flex flex_wrap col" >
              <router-link class="navbar-brand mb-lg-3" id="commercialtabmetrics" to="/commercial/metrics" v-if="metrics_permission">
                <p class="me-3 me-lg-5" :class="activeTab == 'your_metrics' ? 'active' : ''" >
                    {{ metrics_title }}
                </p>
              </router-link>
              <router-link class="navbar-brand mb-lg-3" id="commercialtabjobs" to="/commercial/jobs" v-if="jobs_permission">
                  <p class="me-3 me-lg-5" :class="activeTab == 'jobs' ? 'active' : ''" >
                  {{ jobs_title }}
                  </p>
              </router-link>
                
              <router-link class="navbar-brand mb-lg-3" id="commercialtabinfo" to="/commercial/information" v-if="info_permission">
                  <p class="me-3 me-lg-5" :class="activeTab == 'info' ? 'active' : ''" >
                  {{ info_title }}
                  </p>
              </router-link>
              <router-link class="navbar-brand mb-lg-3" id="commercialtabpayment" to="/commercial/billing" v-if="billing_permission">
                  <p class="me-3 me-lg-5" :class="activeTab == 'billing' ? 'active' : ''" >
                  {{ payments_title }}
                  </p>
              </router-link>
              <router-link class="navbar-brand mb-lg-3" id="commercialtabdocuments" to="/commercial/compliance" v-if="compliance_permission">
                  <p class="me-3 me-lg-5" :class="activeTab == 'compliance' ? 'active' : ''" >
                  {{ documents_title }}
                  </p>
              </router-link>
            </div>
            <div class="col-auto">
              <div class="d-block d-lg-none" @click="scrollToNextTab">
                  <i class="bi bi-caret-right-fill"></i>
              </div>
            </div>
        </div>
      </div>
  
      <div class="tab_body">
        <LittaJobs v-if="activeTab == 'jobs'" @triggerFunction="childLoaded" />
        <YourMetrics v-if="activeTab == 'your_metrics'" @triggerFunction="childLoaded" />
        <ComplicanceDocuments v-if="activeTab == 'compliance'" @triggerFunction="childLoaded" />
        <YourInfo v-if="activeTab == 'info'" @triggerFunction="childLoaded" />
        <BillingPayments v-if="activeTab == 'billing' " @triggerFunction="childLoaded" />
      </div>
    </div>
    <CommonFooter />
  </template>
    
  <script>
    import CommonFooter from '@/components/HeaderFooter/CommonFooter.vue';
    import CommonHeader from '@/components/HeaderFooter/CommonHeader.vue';
    import YourMetrics from '@/components/DashboardTabs/YourMetrics.vue';
    import ComplicanceDocuments from '@/components/DashboardTabs/ComplianceDocuments.vue';
    import LittaJobs from '@/components/DashboardTabs/LittaJobs.vue';
    import YourInfo from '@/components/DashboardTabs/YourInfo.vue';
    import BillingPayments from '@/components/DashboardTabs/BillingPayments.vue';
    import LoadingOverlay from 'vue3-loading-overlay';
    import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
    import { useMyStore } from '@/store';
    import axios from 'axios';
    export default {
      // Component options
      data() {
        return {
          currentTabActive: '',
          activeTab: '',
          jobs_title: '',
          metrics_title: '',
          documents_title: '',
          info_title: '',
          payments_title: '',
          isLoading: false,
          parentFullyLoaded: false,
          myStore: useMyStore(),
          metrics_permission: false,
          jobs_permission: false,
          info_permission: false,
          billing_permission: false,
          compliance_permission: false,
        }
      },
      components: {
        YourMetrics,
        ComplicanceDocuments,
        LittaJobs,
        YourInfo,
        LoadingOverlay,
        BillingPayments,
        CommonFooter,
        CommonHeader,
      },
      methods: {
          scrollToNextTab() {
              const currentActiveElement = document.querySelector('.temp_active');
              if (currentActiveElement) {
                  currentActiveElement.classList.remove('temp_active');
              }

              if (this.currentTabActive == 'commercialtabmetrics') {
                if (this.jobs_permission) {
                  this.currentTabActive = 'commercialtabjobs';
                } else if (this.info_permission) {
                  this.currentTabActive = 'commercialtabinfo';
                } else if (this.billing_permission) {
                  this.currentTabActive = 'commercialtabpayment';
                } else if (this.compliance_permission) {
                  this.currentTabActive = 'commercialtabdocuments';
                }
              } else if (this.currentTabActive == 'commercialtabjobs') {
                if (this.info_permission) {
                  this.currentTabActive = 'commercialtabinfo';
                } else if (this.billing_permission) {
                  this.currentTabActive = 'commercialtabpayment';
                } else if (this.compliance_permission) {
                  this.currentTabActive = 'commercialtabdocuments';
                }
              } else if (this.currentTabActive == 'commercialtabinfo') {
                if (this.billing_permission) {
                  this.currentTabActive = 'commercialtabpayment';
                } else if (this.compliance_permission) {
                  this.currentTabActive = 'commercialtabdocuments';
                }
              } else if (this.currentTabActive == 'commercialtabpayment') {
                if (this.compliance_permission) {
                  this.currentTabActive = 'commercialtabdocuments';
                }
              }
              else if (this.currentTabActive == 'commercialtabdocuments') {
                this.currentTabActive = 'commercialtabdocuments';
              }
              const nextActiveElement = document.getElementById(this.currentTabActive);
              if (nextActiveElement) {
                  nextActiveElement.classList.add('temp_active');
              }
              this.$nextTick(() => {
                  const element = document.getElementById(this.currentTabActive);
                  if (element) {
                      element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                      console.log('scroll to', this.currentTabActive);
                  }
              });
          },
          scrollToPrevTab() {
            const currentActiveElement = document.querySelector('.temp_active');
              if (currentActiveElement) {
                  currentActiveElement.classList.remove('temp_active');
              }

              if (this.currentTabActive == 'commercialtabdocuments') {
                if (this.billing_permission) {
                    this.currentTabActive = 'commercialtabpayment';
                } else if (this.info_permission) {
                    this.currentTabActive = 'commercialtabinfo';
                } else if (this.jobs_permission) {
                    this.currentTabActive = 'commercialtabjobs';
                } else if (this.metrics_permission) {
                    this.currentTabActive = 'commercialtabmetrics';
                }
              } else if (this.currentTabActive == 'commercialtabpayment') {
                if (this.info_permission) {
                    this.currentTabActive = 'commercialtabinfo';
                } else if (this.jobs_permission) {
                    this.currentTabActive = 'commercialtabjobs';
                } else if (this.metrics_permission) {
                    this.currentTabActive = 'commercialtabmetrics';
                }
              } else if (this.currentTabActive == 'commercialtabinfo') {
                if (this.jobs_permission) {
                    this.currentTabActive = 'commercialtabjobs';
                } else if (this.metrics_permission) {
                    this.currentTabActive = 'commercialtabmetrics';
                }
              } else if (this.currentTabActive == 'commercialtabjobs') {
                if (this.metrics_permission) {
                    this.currentTabActive = 'commercialtabmetrics';
                }
              }
              else if (this.currentTabActive == 'commercialtabmetrics') {
                this.currentTabActive = 'commercialtabmetrics';
              }
              const nextActiveElement = document.getElementById(this.currentTabActive);
              if (nextActiveElement) {
                  nextActiveElement.classList.add('temp_active');
              }
              this.$nextTick(() => {
                  const element = document.getElementById(this.currentTabActive);
                  if (element) {
                      element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                      console.log('scroll to', this.currentTabActive);
                  }
              });
          },
          logout() {
              // Remove user details from local storage
              localStorage.removeItem('commercials_user_data');
  
              // Redirect the user to the login page
              this.$router.push('/commercial/login');
          },
          handleLoading() {
            if (this.parentFullyLoaded && this.childFullyLoaded) {
              this.isLoading = false;
            }
          },
          childLoaded() {
            this.childFullyLoaded = true;
            this.handleLoading();
          },
          openTab(val){
            this.myStore.showFooter = true;
            this.isLoading = true;
            this.childFullyLoaded = false;
            this.activeTab = val;
          },
          tempAllActive(){
            this.metrics_permission = true;
            this.jobs_permission = true;
            this.info_permission = true;
            this.billing_permission = true;
            this.compliance_permission = true;
          },
      },
      async mounted() {
          this.isLoading = true;
          let user_details = localStorage.getItem('commercials_user_data');
          user_details = JSON.parse(user_details);
          console.log('user details: -> ', user_details);
          let user_id = user_details.uid;
          if (!this.myStore.valid_user){
            if (this.myStore.valid_user == null) {
              axios.get(`https://portal-litta-api.web.app/commercial/users/${user_id}`).then(response=>{
                console.log('response valid_user: ', response);
                if (response.data.data.isValidUser != true) {
                  this.$router.push('/partner/metrics');
                } else {
                  this.myStore.setValidUser(true);
                }
              }).catch(error=>{
                console.log('error:', error);
              });
            } else {
              this.$router.push('/partner/metrics');
            }
          }
  
          if (this.myStore.commercial_permissions == null) {
            await axios.get(`https://portal-litta-api.web.app/commercial/users/${user_id}`).then(response=>{
              this.myStore.commercial_permissions = response;
            }).catch(error=>{
              console.log('error', error);
            });
          }
  
          if (this.myStore.commercial_permissions) {
            this.myStore.commercialCompanyName = this.myStore.commercial_permissions.data.data.accountName;
          }
          this.myStore.commercial_permissions.data.data.permissions?.tabAccess.map((item)=>{
            if (item.name == 'client_information') {
              this.info_permission = true;
            }
            if (item.name == 'client_metrics') {
              this.metrics_permission = true;
            }
            if (item.name == 'litta_compliance_documents') {
              this.compliance_permission = true;
            }
            if (item.name == 'litta_jobs') {
              this.jobs_permission = true;
            }
            if (item.name == 'billing') {
              this.billing_permission = true;
            }
          });
          // this.tempAllActive();
          if (this.info_permission) {
            this.activeTab = 'info';
            //   this.$router.push('/commercial/information');
            } else if (this.metrics_permission) {
          // this.activeTab = 'your_metrics';
          this.$router.push('/commercial/metrics');
        } else if (this.jobs_permission) {
          // this.activeTab = 'jobs';
          this.$router.push('/commercial/jobs');
        } else if (this.billing_permission) {
          // this.activeTab = 'billing';
          this.$router.push('/commercial/billing');
        } else if (this.compliance_permission) {
          // this.activeTab = 'compliance';
          this.$router.push('/commercial/compliance');
        }

            this.currentTabActive = 'commercialtabinfo';
            this.$nextTick(() => {
                const element = document.getElementById(this.currentTabActive);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                    console.log('scroll to', this.currentTabActive);
                }
            });
          // switch (first_tab) {
          //   case 'info':
          //     this.activeTab = 'info';
          //     break;
          //   case 'metrics':
          //     this.activeTab = 'your_metrics';
          //     break;
          //   case 'compliance':
          //     this.activeTab = 'compliance';
          //     break;
          //   case 'jobs':
          //     this.activeTab = 'jobs';
          //     break;
          //   case 'billing':
          //     this.activeTab = 'billing';
          //     break;
          //   default:
          //     this.activeTab = 'your_metrics';
          //     break;
          // }
          this.parentFullyLoaded = false;
          this.childFullyLoaded - false;
          if (this.myStore.info_title == null) {
            await axios.get('https://portal-litta-api.web.app/commercial/content').then(response=>{
              response.data.data.map(item=>{
                if (item.type == 'tab' && item.tag == 'client_information') {
                  this.info_title = item.content;
                  this.myStore.setInfoTitle(this.info_title);
                }
                if (item.type == 'tab' && item.tag == 'client_metrics') {
                  this.metrics_title = item.content;
                  this.myStore.setMetricsTitle(this.metrics_title);
                }
                if (item.type == 'tab' && item.tag == 'litta_compliance_documents') {
                  this.documents_title = item.content;
                  this.myStore.setDocumentsTitle(this.documents_title);
                }
                if (item.type == 'tab' && item.tag == 'litta_jobs') {
                  this.jobs_title = item.content;
                  this.myStore.setJobsTitle(this.jobs_title);
                }
                if (item.type == 'tab' && item.tag == 'billing') {
                  this.payments_title = item.content;
                  this.myStore.setPaymentsTitle(this.payments_title);
                }
              });
            }).catch(error=>{
              console.log('error: ', error);
            });
          } else {
            this.info_title = this.myStore.info_title;
            this.metrics_title = this.myStore.metrics_title;
            this.documents_title = this.myStore.documents_title;
            this.jobs_title = this.myStore.jobs_title;
            this.payments_title = this.myStore.payments_title;
          }
          this.myStore.showFooter = true;
          this.parentFullyLoaded = true;
          this.handleLoading();
      },
    }
  </script>
    
  <style scoped>
    /* Component styles */
    .body{
      background-color: #F7FAFC;
      min-height: 90vh;
    }
    
    .class_css_tab_headers p{
      font-family: poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      letter-spacing: 0.02em;
      text-align: left;
      cursor: pointer;
    }

    .temp_active p{
      border-bottom: 2px solid gray;
    }
  
    .class_css_tab_headers p.active{
      font-family: poppins;
      font-size: 15px;
      font-weight: 800;
      line-height: 19.6px;
      letter-spacing: 0.02em;
      text-align: left;
      border-bottom: 2px solid #06CCCC;
      padding-bottom: 5px;
    }
  
    .flex_wrap{
      flex-wrap: wrap;
    }
  
    .logout_button{
        background: #FFE7E0;
        color: #D41866;
        font-size: 15px;
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 50px;
        border: none;
    }
  
    .company_name_col{
      align-content: center;
    }
  
    .company_name{
      font-family: Poppins;
      font-size: 28px;
      font-weight: 800;
      line-height: 31.68px;
      text-align: left;
      margin-bottom: 0;
    }
    .headerr{
      padding-top: 0px;
      border-top: 2px solid #06CCCC;
    }
  
    @media (max-width:764px) {
      .flex_wrap{
          flex-wrap: nowrap;
          overflow-x: scroll;
      }
      .flex_wrap::-webkit-scrollbar {
          display: none; /* Hide the scrollbar */
      }
    }
  </style>