import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { pinia } from '@/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'jquery';
import 'bootstrap';
import '@/assets/global_styles/styles.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'sweetalert2/dist/sweetalert2.min.css';
// import VueToastr from 'vue-toastr';

const app = createApp(App);
app.use(router);
app.use(pinia);
// app.use(VueToastr, {
// });
app.mount('#app');