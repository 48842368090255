<template>
    <LoadingOverlay :active="isLoading" :can-cancel="false" :is-full-page="true" />
    <div class="body">
        <CommonHeader />
        <div class="container">
            <div class="row">
                <div class="col-12 col-xl-5 justify-content-center mx-auto">
                    <div class="login_container ps-2 pe-2 ps-lg-3 pe-lg-3 ps-xl-5 pe-xl-5">
                        <img src="@/assets/auth/forgot_icon.svg" class="mt-4" alt="">
                        <p class="login_heading mt-3">
                            Retrieve your password
                        </p>

                        <div class="mt-4 form-group">
                            <div class="input-container mb-5" :class="{ 'has-error': emailError }">
                                <p>
                                    Enter email address and select Send Email.
                                </p>
                                <div class="input_wrapper">
                                    <input type="email" class="form-control auth_input" placeholder="Email" v-model="email" @keyup.enter="sendEmail" @input="clearErrors('email')">
                                    <i class="bi bi-person input_icon"></i>
                                </div>
                                <p class="error-message text-left ps-3" v-if="emailError">{{ emailError }}</p>
                            </div>

                            <div class="row mb-3">
                                <div class="col">
                                    <button class="cancel_button" @click="goToLogin()">
                                        Cancel
                                    </button>
                                </div>
                                <div class="col">
                                    <button class="send_email" @click="sendEmail()">
                                        Send Email
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CommonHeader from '@/components/AuthHeader/PartnerHeader.vue';
    import { auth } from '@/firebase';
    import { sendPasswordResetEmail } from 'firebase/auth';
    import LoadingOverlay from 'vue3-loading-overlay';
    import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
    import Swal from 'sweetalert2/dist/sweetalert2.js';

    export default {
        data() {
            return {
                email: '',
                emailError: '',
                isLoading: false,
            }
        },
        components: {
            CommonHeader,
            LoadingOverlay,
        },
        methods: {
            goToLogin(){
                this.$router.push('/partner/login');
            },
            clearErrors(field) {
                if (field === 'email') {
                    this.emailError = '';
                }
            },
            async sendEmail() {
                this.isLoading = true;
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!this.email) {

                    this.emailError = 'Please fill email field';
                    this.isLoading = false;
                    return;
                }
                if (!emailRegex.test(this.email)) {
                    this.emailError = 'Please enter a valid email address';
                    this.isLoading = false;
                    return;
                }
                
                try {
                    // Send password reset email using Firebase Authentication
                    await sendPasswordResetEmail(auth, this.email);
                    // Display a success message or redirect to a confirmation page
                    this.isLoading = false;
                    Swal.fire({
                        title: 'Success!',
                        text: 'Password reset email sent successfully.',
                        icon: 'success'
                    });
                    console.log('Password reset email sent successfully.');
                } catch (error) {
                    console.error('Error sending password reset email:', error.message);
                    this.isLoading = false;
                    Swal.fire({
                        title: 'Error!',
                        text: error.message,
                        icon: 'error'
                    });
                    // Handle errors, display error message to the user, etc.
                }
            },
        },
    }
</script>

<style scoped>
    .body{
        background-color: #F7FAFC;
        min-height: 90vh;
    }

    .login_container{
        border: 1px solid #7B37EF;
        text-align: center;
        border-radius: 10px;
    }

    .login_heading{
        font-size: 24px;
        font-weight: 700;
        vertical-align: top;
    }

    .login_heading .tm{
        font-size: 10px;
        vertical-align: top;
        position: relative;
        top: 5px;
    }

    .auth_input{
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 50px;
    }

    .input_wrapper {
        position: relative;
    }

    .input_wrapper input {
        padding-left: 30px; /* Space for the icon */
    }

    .input_icon {
        position: absolute;
        left: 10px; /* Adjust as needed */
        top: 50%;
        transform: translateY(-50%);
    }

    .eye_icon {
        position: absolute;
        right: 20px; /* Adjust as needed */
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .password_input{
        padding-right: 40px;
    }

    .forgot_password{
        color: #000;
        font-size: 18px;
        text-decoration: underline;
        text-align: right;
        cursor: pointer;
    }

    .send_email{
        background-color: #7B37EF;
        color: #fff;
        width: 100%;
        border-radius: 15px;
        padding: 10px 10px;
        border: none;
    }

    .cancel_button{
        background-color: transparent;
        color: #7B37EF;
        width: 100%;
        border-radius: 15px;
        padding: 10px 10px;
        border: 1px solid #7B37EF;
    }

    .google_login{
        background: rgb(66 133 244);
        width: 100%;
        padding: 5px 5px;
        color: #fff;
        border-radius: 50px;
        border: none;
    }

    .google_login img{
        float: left;
    }

    .apple_login{
        background: #fff;
        width: 100%;
        padding: 5px 5px;
        color: #000;
        border-radius: 50px;
        border: 1px solid #000;
    }

    .dont_account{
        color: #000;
        font-size: 19px;
    }

    .dont_account span{
        font-size: 10px;
        vertical-align: top;
        position: relative;
        top: 5px;
    }

    .signup_button{
        color: #7B37EF;
        border: 2px solid #7B37EF;
        width: 100%;
        background: transparent;
        padding: 5px 10px;
        border-radius: 15px;
    }

    .has-error input {
        border-color: red;
    }

    .error-message {
        color: red;
        font-size: 12px;
        text-align: left;
        margin-top: 5px;
    }
</style>
