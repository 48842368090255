<template>
    <div class="body container-fluid">
        <div class="header p-3 p-xl-5 pb-3 pb-xl-3">
            <div class="row">
                <div class="col-12 col-md-auto pb-3 pb-xl-0">
                    <img src="@/assets/website_logo.svg" class="website_logo" alt="">
                </div>
                <div class="col-12 col-md company_name_col pb-3 pb-xl-0">
                    <p class="company_name ps-md-2">
                        ABCD Company Pvt. Ltd.
                    </p>
                </div>
                <div class="col-12 col-md-auto pb-3 pb-xl-0">
                    <button class="logout_button" @click="logout">
                        LOGOUT
                    </button>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-12 col-xl-6 justify-content-center mx-auto">
                    <div class="login_container ps-2 pe-2 ps-lg-3 pe-lg-3 ps-xl-5 pe-xl-5">
                        <p class="login_heading mt-3">
                            Sorry, you are not authorised or your account has not been set up.
                        </p>

                        <button class="go_back mb-3" @click="logout">Go Back</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {
            logout() {
                // Remove user details from local storage
                localStorage.removeItem('commercials_user_data');

                // Redirect the user to the login page
                this.$router.push('/commercial/login');
            },
        },
    }
</script>

<style scoped>
    .body{
        background-color: #F7FAFC;
        min-height: 90vh;
    }

    .logout_button{
        background: #FFE7E0;
        color: #D41866;
        font-size: 15px;
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 50px;
        border: none;
    }

    .login_heading{
        font-size: 24px;
        font-weight: 700;
        vertical-align: top;
    }

    .login_container{
        border: 1px solid rgb(213 224 213);
        text-align: center;
        border-radius: 10px;
    }

    .go_back{
        background-color: rgb(6 204 204);
        color: #fff;
        width: 100%;
        border-radius: 15px;
        padding: 10px 10px;
        border: none;
    }

    .company_name_col{
        align-content: center;
    }

    .company_name{
        font-family: Poppins;
        font-size: 28px;
        font-weight: 800;
        line-height: 31.68px;
        text-align: left;
        margin-bottom: 0;
    }
</style>