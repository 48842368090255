<template>
  <LoadingOverlay :active="isLoading" :can-cancel="false" :is-full-page="true" />
    <div class="body container-fluid">
      <div class="header p-3 p-xl-5 pb-3 pb-xl-3">
        <div class="row">
          <div class="col-12 col-md-auto pb-3 pb-xl-0">
            <img src="@/assets/website_logo.svg" class="website_logo" alt="">
          </div>
          <div class="col-12 col-md company_name_col pb-3 pb-xl-0">
            <p class="company_name ps-md-5">
              ABCD Company Pvt. Ltd.
            </p>
          </div>
          <div class="col-12 col-md-auto pb-3 pb-xl-0">
            <button class="logout_button" @click="logout">
              LOGOUT
            </button>
          </div>
        </div>
  
        <div class="mt-5 class_css_tab_headers d-flex flex_wrap" >
          <router-link class="navbar-brand mb-3" to="/litta-jobs">
            <p class="me-5">
              {{ jobs_title }}
            </p>
          </router-link>
  
          <router-link class="navbar-brand mb-3" to="/">
            <p class="me-5">
              {{ metrics_title }}
            </p>
          </router-link>
  
          <router-link class="navbar-brand mb-3" to="/compliance-documents">
            <p class="me-5">
              {{ documents_title }}
            </p>
          </router-link>
  
          <router-link class="navbar-brand mb-3" to="/account-info">
            <p class="me-5 active">
              {{ info_title }}
            </p>
          </router-link>
        </div>
      </div>
  
      <div class="tab_body">
        <LittaJobs v-if="activeTab == 'jobs'" @triggerFunction="childLoaded" />
        <YourMetrics v-if="activeTab == 'your_metrics'" @triggerFunction="childLoaded" />
        <ComplicanceDocuments v-if="activeTab == 'compliance'" @triggerFunction="childLoaded" />
        <YourInfo v-if="activeTab == 'info'" @triggerFunction="childLoaded" />
      </div>
    </div>
  </template>
    
  <script>
    import YourMetrics from '@/components/DashboardTabs/YourMetrics.vue';
    import ComplicanceDocuments from '@/components/DashboardTabs/ComplianceDocuments.vue';
    import LittaJobs from '@/components/DashboardTabs/LittaJobs.vue';
    import YourInfo from '@/components/DashboardTabs/YourInfo.vue';
    import LoadingOverlay from 'vue3-loading-overlay';
    import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
    import axios from 'axios';
    export default {
      // Component options
      data() {
        return {
          activeTab: 'info',
          jobs_title: '',
          metrics_title: '',
          documents_title: '',
          info_title: '',
          isLoading: false,
          parentFullyLoaded: false,
          childFullyLoaded: false,
        }
      },
      components: {
        YourMetrics,
        ComplicanceDocuments,
        LittaJobs,
        YourInfo,
        LoadingOverlay,
      },
      methods: {
          logout() {
              // Remove user details from local storage
              localStorage.removeItem('commercials_user_data');
  
              // Redirect the user to the login page
              this.$router.push('/login');
          },
          handleLoading() {
            if (this.parentFullyLoaded && this.childFullyLoaded) {
              this.isLoading = false;
            }
          },
          childLoaded() {
            this.childFullyLoaded = true;
            this.handleLoading();
          },
      },
      async mounted() {
          let user_details = localStorage.getItem('commercials_user_data');
          user_details = JSON.parse(user_details);
          console.log('user details: -> ', user_details);
          let user_id = user_details.uid;

          axios.get(`https://portal-litta-api.web.app/commercial/users/${user_id}`).then(response=>{
            console.log('response valid_user: ', response);
            if (response.data.data.isValidUser != true) {
              this.$router.push('/commercial/user-notvalid');
            }
          }).catch(error=>{
            console.log('error:', error);
          });
          this.isLoading = true;
          this.parentFullyLoaded = false;
          this.childFullyLoaded - false;
          await axios.get('https://portal-litta-api.web.app/commercial/content').then(response=>{
            response.data.data.map(item=>{
              if (item.type == 'tab' && item.tag == 'client_information') {
                this.info_title = item.content;
              }
              if (item.type == 'tab' && item.tag == 'client_metrics') {
                this.metrics_title = item.content;
              }
              if (item.type == 'tab' && item.tag == 'litta_compliance_documents') {
                this.documents_title = item.content;
              }
              if (item.type == 'tab' && item.tag == 'litta_jobs') {
                this.jobs_title = item.content;
              }
            });
          }).catch(error=>{
            console.log('error: ', error);
          });
          this.parentFullyLoaded = true;
          this.handleLoading();
      },
    }
  </script>
    
  <style scoped>
    /* Component styles */
    .body{
      background-color: #F7FAFC;
      min-height: 90vh;
    }
    
    .class_css_tab_headers p{
      font-family: poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      letter-spacing: 0.02em;
      text-align: left;
      cursor: pointer;
    }
  
    .class_css_tab_headers p.active{
      font-family: poppins;
      font-size: 15px;
      font-weight: 800;
      line-height: 19.6px;
      letter-spacing: 0.02em;
      text-align: left;
      border-bottom: 2px solid #06CCCC;
      padding-bottom: 5px;
    }
  
    .flex_wrap{
      flex-wrap: wrap;
    }
  
    .logout_button{
        background: #FFE7E0;
        color: #D41866;
        font-size: 15px;
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 50px;
        border: none;
    }

    .company_name_col{
      align-content: center;
    }

    .company_name{
      font-family: Poppins;
      font-size: 28px;
      font-weight: 800;
      line-height: 31.68px;
      text-align: left;
      margin-bottom: 0;
    }
  </style>