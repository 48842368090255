<template>
  <LoadingOverlay :active="isLoading" :can-cancel="false" :is-full-page="true" />
  <div class="body container-fluid">
    <div class="headerr p-0 pt-3 p-xl-5 pb-3 pb-xl-3">
      <CommonHeader />

      <div class="d-flex mt-0 mt-xl-5">
        <div class="d-block d-lg-none" @click="scrollToPrevTab">
          <i class="bi bi-caret-left-fill"></i>
        </div>
        <div class="class_css_tab_headers d-flex flex_wrap">
          <router-link
            class="navbar-brand mb-lg-3"
            to="/partner/metrics"
            id="partnermetricstab"
          >
            <p
              class="me-3 me-lg-5 mb-0"
              :class="activeTab == 'partner_metrics' ? 'active' : ''"
              v-if="metrics_title.active"
            >
              {{ metrics_title.content }}
            </p>
          </router-link>

          <router-link
            class="navbar-brand mb-lg-3"
            to="/partner/jobs"
            id="partnerjobstab"
          >
            <p
              class="me-3 me-lg-5 mb-0"
              :class="activeTab == 'partner_jobs' ? 'active' : ''"
              v-if="jobs_title.active"
            >
              {{ jobs_title.content }}
            </p>
          </router-link>

          <router-link
            class="navbar-brand mb-lg-3"
            to="/partner/info"
            id="partnerinfotab"
          >
            <p
              class="me-3 me-lg-5 mb-0"
              :class="activeTab == 'partner_information' ? 'active' : ''"
              v-if="info_title.active"
            >
              {{ info_title.content }}
            </p>
          </router-link>

          <router-link
            class="navbar-brand mb-lg-3"
            to="/partner/billing"
            id="partnerbillingtab"
          >
            <p
              class="me-3 me-lg-5 mb-0"
              :class="activeTab == 'partner_billing' ? 'active' : ''"
              v-if="billing_title.active"
            >
              {{ billing_title.content }}
            </p>
          </router-link>

          <!-- <router-link class="navbar-brand mb-lg-3" to="/partner/videos" id="partnervideotab">
                        <p class="me-3 me-lg-5 mb-0" :class="activeTab == 'partner_videos' ? 'active' : ''" v-if="videos_title.active">
                            {{ videos_title.content }}
                        </p>
                    </router-link> -->

          <router-link
            class="navbar-brand mb-lg-3"
            to="/partner/drivers"
            id="partnerdriverstab"
          >
            <p
              class="me-3 me-lg-5 mb-0"
              :class="activeTab == 'partner_drivers' ? 'active' : ''"
              v-if="drivers_title.active"
            >
              {{ drivers_title.content }}
            </p>
          </router-link>

          <router-link
            class="navbar-brand mb-lg-3"
            to="/partner/vehicles"
            id="partnervehiclestab"
          >
            <p
              class="me-3 me-lg-5 mb-0"
              :class="activeTab == 'partner_vehicles' ? 'active' : ''"
              v-if="vehicles_title.active"
            >
              {{ vehicles_title.content }}
            </p>
          </router-link>

          <!-- <router-link class="navbar-brand mb-lg-3" to="/partner/compliance" id="partnercompliancetab">
                        <p class="me-3 me-lg-5 mb-0" :class="activeTab == 'partner_compliance' ? 'active' : ''">
                            Compliance
                        </p>
                    </router-link> -->
        </div>
        <div class="d-block d-lg-none" @click="scrollToNextTab">
          <i class="bi bi-caret-right-fill"></i>
        </div>
      </div>
    </div>

    <div class="tab_body">
      <PartnerMetrics
        v-if="activeTab == 'partner_metrics'"
        @triggerFunction="childLoaded"
      />
      <PartnerInfo
        v-if="activeTab == 'partner_information'"
        @triggerFunction="childLoaded"
      />
      <PartnerJobs v-if="activeTab == 'partner_jobs'" @triggerFunction="childLoaded" />
      <PartnerDocuments
        v-if="activeTab == 'partner_documents'"
        @triggerFunction="childLoaded"
      />
      <PartnerPayments
        v-if="activeTab == 'partner_billing'"
        @triggerFunction="childLoaded"
      />
      <PartnerDrivers
        v-if="activeTab == 'partner_drivers'"
        @triggerFunction="childLoaded"
      />
      <PartnerVideo v-if="activeTab == 'partner_videos'" @triggerFunction="childLoaded" />
      <PartnerVehicles
        v-if="activeTab == 'partner_vehicles'"
        @triggerFunction="childLoaded"
      />
    </div>
  </div>
  <CommonFooter />
</template>

<script>
import CommonFooter from "@/components/PartnerHeaderFooter/CommonFooter.vue";
import CommonHeader from "@/components/PartnerHeaderFooter/CommonHeader.vue";
import PartnerMetrics from "@/components/PartnerTabs/PartnerMetrics.vue";
import PartnerInfo from "@/components/PartnerTabs/PartnerInfo.vue";
import PartnerJobs from "@/components/PartnerTabs/PartnerJobs.vue";
import PartnerDocuments from "@/components/PartnerTabs/PartnerDocuments.vue";
import PartnerPayments from "@/components/PartnerTabs/PartnerPayments.vue";
import PartnerDrivers from "@/components/PartnerTabs/PartnerDrivers.vue";
import PartnerVideo from "@/components/PartnerTabs/PartnerVideo.vue";
import PartnerVehicles from "@/components/PartnerTabs/PartnerVehicles.vue";
import LoadingOverlay from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { useMyStore } from "@/store";
import axios from "axios";
export default {
  data() {
    return {
      currentTabActive: "",
      activeTab: "",
      myStore: useMyStore(),
      jobs_title: "",
      metrics_title: "",
      info_title: "",
      billing_title: "",
      videos_title: "",
      drivers_title: "",
      vehicles_title: "",
      isLoading: false,
      parentFullyLoaded: false,
    };
  },
  components: {
    PartnerMetrics,
    PartnerInfo,
    PartnerJobs,
    PartnerDocuments,
    PartnerPayments,
    LoadingOverlay,
    CommonFooter,
    PartnerDrivers,
    PartnerVideo,
    PartnerVehicles,
    CommonHeader,
  },
  methods: {
    scrollToNextTab() {
      const currentActiveElement = document.querySelector(".temp_active");
      if (currentActiveElement) {
        currentActiveElement.classList.remove("temp_active");
      }
      if (this.currentTabActive == "partnermetricstab") {
        this.currentTabActive = "partnerjobstab";
      } else if (this.currentTabActive == "partnerjobstab") {
        this.currentTabActive = "partnerinfotab";
      } else if (this.currentTabActive == "partnerinfotab") {
        this.currentTabActive = "partnerbillingtab";
      } else if (this.currentTabActive == "partnerbillingtab") {
        this.currentTabActive = "partnervideotab";
      } else if (this.currentTabActive == "partnervideotab") {
        this.currentTabActive = "partnerdriverstab";
      } else if (this.currentTabActive == "partnerdriverstab") {
        this.currentTabActive = "partnervehiclestab";
      } else if (this.currentTabActive == "partnervehiclestab") {
        this.currentTabActive = "partnervehiclestab";
      }
      const nextActiveElement = document.getElementById(this.currentTabActive);
      if (nextActiveElement) {
        nextActiveElement.classList.add("temp_active");
      }
      this.$nextTick(() => {
        const element = document.getElementById(this.currentTabActive);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
          console.log("scroll to", this.currentTabActive);
        }
      });
    },
    scrollToPrevTab() {
      const currentActiveElement = document.querySelector(".temp_active");
      if (currentActiveElement) {
        currentActiveElement.classList.remove("temp_active");
      }
      if (this.currentTabActive == "partnervehiclestab") {
        this.currentTabActive = "partnerdriverstab";
      } else if (this.currentTabActive == "partnerdriverstab") {
        this.currentTabActive = "partnervideotab";
      } else if (this.currentTabActive == "partnervideotab") {
        this.currentTabActive = "partnerbillingtab";
      } else if (this.currentTabActive == "partnerbillingtab") {
        this.currentTabActive = "partnerinfotab";
      } else if (this.currentTabActive == "partnerinfotab") {
        this.currentTabActive = "partnerjobstab";
      } else if (this.currentTabActive == "partnerjobstab") {
        this.currentTabActive = "partnermetricstab";
      } else if (this.currentTabActive == "partnermetricstab") {
        this.currentTabActive = "partnermetricstab";
      }
      const nextActiveElement = document.getElementById(this.currentTabActive);
      if (nextActiveElement) {
        nextActiveElement.classList.add("temp_active");
      }
      this.$nextTick(() => {
        const element = document.getElementById(this.currentTabActive);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
          console.log("scroll to", this.currentTabActive);
        }
      });
    },
    handleLoading() {
      if (this.parentFullyLoaded && this.childFullyLoaded) {
        this.isLoading = false;
      }
    },
    childLoaded() {
      this.childFullyLoaded = true;
      this.handleLoading();
    },
    openTab(val) {
      this.isLoading = true;
      this.myStore.showFooter = true;
      this.activeTab = val;
    },
    logout() {
      // Remove user details from local storage
      localStorage.removeItem("commercials_user_data");

      // Redirect the user to the login page
      this.$router.push("/partner/login");
    },
  },
  async mounted() {
    this.isLoading = true;
    let user_details = localStorage.getItem("commercials_user_data");
    user_details = JSON.parse(user_details);
    console.log("user details: -> ", user_details);
    let user_id = user_details.uid;
    if (!this.myStore.partner_valid_user) {
      if (this.myStore.partner_valid_user == null) {
        axios
          .get(`https://portal-litta-api.web.app/partners/users/${user_id}`)
          .then((response) => {
            console.log("response partner_valid_user: ", response);
            if (response.data.data.isValidUser != true) {
              this.$router.push("/commercial/metrics");
            } else {
              this.myStore.setValidUser(true);
            }
          })
          .catch((error) => {
            console.log("error:", error);
            this.$router.push("/commercial/metrics");
          });
      } else {
        this.$router.push("/commercial/metrics");
      }
    }
    if (this.myStore.partnerCompanyName == null) {
      this.myStore.fetchPartnerCompanyName();
    }
    this.parentFullyLoaded = false;
    // let temp_response = [];
    if (this.myStore.partner_dashboard == null) {
      await axios
        .get("https://portal-litta-api.web.app/partners/content")
        .then((response) => {
          this.myStore.partner_dashboard = response;
          // temp_response = this.myStore.partner_dashboard;
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      // temp_response = this.myStore.partner_dashboard;
    }
    this.currentTabActive = "partnervehiclestab";
    this.$nextTick(() => {
      const element = document.getElementById(this.currentTabActive);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
        console.log("scroll to", this.currentTabActive);
      }
    });
    // console.log(this.myStore.partner_dashboard);
    this.myStore.partner_dashboard.data.data.map((item) => {
      console.log(item);
      if (item.type) {
        if (item.type[0] == "tab" && item.tag == "litta_jobs") {
          this.jobs_title = item;
        }
        if (item.type[0] == "tab" && item.tag == "client_metrics") {
          this.metrics_title = item;
        }
        if (item.type[0] == "tab" && item.tag == "client_information") {
          this.info_title = item;
        }
        if (item.type[0] == "tab" && item.tag == "billing") {
          this.billing_title = item;
        }
        if (item.type[0] == "tab" && item.tag == "video") {
          this.videos_title = item;
        }
        if (item.type[0] == "tab" && item.tag == "drivers") {
          this.drivers_title = item;
        }
        if (item.type[0] == "tab" && item.tag == "vehicle") {
          this.vehicles_title = item;
        }
      }
    });
    this.activeTab = "partner_vehicles";
    this.parentFullyLoaded = true;
    this.handleLoading();
  },
};
</script>

<style scoped>
.body {
  background-color: #f7fafc;
  min-height: 90vh;
}

.class_css_tab_headers p {
  font-family: poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: 0.02em;
  text-align: left;
  cursor: pointer;
}

.temp_active p {
  border-bottom: 2px solid gray;
}

.class_css_tab_headers p.active {
  font-family: poppins;
  font-size: 15px;
  font-weight: 800;
  line-height: 19.6px;
  letter-spacing: 0.02em;
  text-align: left;
  border-bottom: 2px solid #7b37ef;
  padding-bottom: 5px;
}

.flex_wrap {
  flex-wrap: wrap;
}

.logout_button {
  background: #ffe7e0;
  color: #d41866;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 50px;
  border: none;
}

.company_name_col {
  align-content: center;
}

.company_name {
  font-family: Poppins;
  font-size: 28px;
  font-weight: 800;
  line-height: 31.68px;
  text-align: left;
  margin-bottom: 0;
}

.headerr {
  padding-top: 0px;
  border-top: 2px solid #7b37ef;
}

@media (max-width: 764px) {
  .flex_wrap {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  .flex_wrap::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
}
</style>
