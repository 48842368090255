<template>
    <!-- <div class="p-0 p-xl-5 pt-0 pt-xl-0"> -->
    <div v-if="!noDataFound">
        <div class="row mb-3">
            <div class="col-12 col-xl">
                <p class="p-0 p-xl-5 pt-0 pb-0 pb-xl-0 pt-xl-0 main_text ps-1">
                    {{ subTitle }}
                </p>
            </div>

            <div class="col-12 col-xl-auto d-flex">
                <button class="btn btn-custom me-2" type="button" data-bs-toggle="modal" data-bs-target="#jobsSort">
                    <i class="bi bi-sort-down"></i> <b>Sort</b>
                </button>

                <div class="modal fade" id="jobsSort" tabindex="-1" aria-labelledby="jobsSortLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="jobsSortLabel">Sort Options</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group mb-3">
                                <label>
                                    Select Column
                                </label>
                                <select class="form-select" v-model="selectedSortColumn">
                                    <option :value="option" v-for="(option,index) in sortColumnsCollection" :key="index">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <hr>
                            <div class="form-group mb-3">
                                <label>
                                    Select Order
                                </label>
                                <select class="form-select" v-model="sort_order">
                                    <option value="ASC">ASC</option>
                                    <option value="DESC">DESC</option>
                                </select>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="clearSort()">Clear Sort</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" @click="performSort()" data-bs-dismiss="modal">Apply Sort</button>
                        </div>
                        </div>
                    </div>
                </div>

                <button class="btn btn-custom" type="button" data-bs-toggle="modal" data-bs-target="#jobsFilter">
                    <i class="bi bi-funnel-fill me-1"></i> <b>Filter</b>
                </button>

                <div class="modal fade" id="jobsFilter" tabindex="-1" aria-labelledby="jobsFilterLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-width">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="jobsFilterLabel">Filter Options</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body modal-width">
                            <div class="form-group mb-3">
                                <label>Status</label>
                                <select class="form-select" v-model="selectedStatus">
                                    <option value=""> - Select Status - </option>
                                    <option :value="option" v-for="(option,index) in statusCollection" :key="index">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-xl-5 col-12">
                                    <ul class="list-group filter_time_options">
                                        <li class="list-group-item" :class="checkMonthSelected() ? 'active' : ''" @click="fetchThisMonthData()">This Month</li>
                                        <li class="list-group-item" :class="checkLastMonthSelected() ? 'active' : ''" @click="lastMonthData()">Last Month</li>
                                        <li class="list-group-item" :class="checkThisQuarterSelected() ? 'active' : ''" @click="fetchThisQuarterData()">This Quarter</li>
                                        <li class="list-group-item" :class="checkLastQuarterSelected() ? 'active' : ''" @click="lastQuarterData()">Last Quarter</li>
                                        <li class="list-group-item" :class="checkThisYearSelected() ? 'active' : ''" @click="fetchThisYearData()">This Year</li>
                                    </ul>
                                </div>
                                <div class="col-xl-7 col-12">
                                    <div class="form-group mb-3" v-if="default_selection != 'All'">
                                        <label>Select Months</label>
                                        <Calendar
                                            v-model:checkIn="checkIn" 
                                            v-model:checkOut="checkOut"
                                            :alwaysVisible="true"
                                            locale="en"
                                            :bookingColor="bookingColor"
                                            :disabledDaysBeforeDayDate="false"
                                            :placeholder="calendarPlaceholder"
                                        ></Calendar>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group mb-3">
                                <label>
                                    From Date:
                                </label>
                                <input type="date" class="form-control" v-model="selectedFrom">
                            </div>

                            <div class="form-group mb-3">
                                <label>
                                    To Date:
                                </label>
                                <input type="date" class="form-control" v-model="selectedTo">
                            </div> -->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="clearFilter()">Clear Filter</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" @click="performFilter()" data-bs-dismiss="modal">Apply Filter</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-none d-xl-block">
            <div class="table-container">
                <div class="table-responsive tableFixHead">
                    <table class="table">
                        <thead>
                            <tr>
                                <template v-for="(field, index) in tableFields" :key="index">
                                    <th v-if="field.type != 'hover'" scope="col">{{ field.title }}</th>
                                </template>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in tableData" :key="index">
                                <template v-for="(field, idx) in row" :key="idx">
                                    <td v-if="field.type != 'hover'" >
                                        <template v-if="tooltipExist(row,field)">
                                            <p class="cursor_pointer" v-if="field?.type == 'text'" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                {{ field.value == null ? '-' : addCommas(field.value) }}
                                            </p>
                                            <div v-if="field.type == 'bubble'" class="dynamic_bubble cursor_pointer" :style="`background: ${field.bubbleColour};`" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                {{ field.value }}
                                            </div>
                                            <a class="cursor_pointer" :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                <button class="btn btn-secondary w-100 text-center">
                                                    Document
                                                </button>
                                            </a>
                                            <a class="cursor_pointer" :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                                <button class="btn btn-primary w-100 text-center">
                                                    Document
                                                </button>
                                            </a>
                                            <img v-if="field.type == 'image'" :src="field.value" class="table_image" alt="" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                        </template>
                                        <template v-else>
                                            <span v-if="field?.type == 'text'">
                                                {{ field?.fieldName == 'payout' ? field.value == null ? '-' : '£' + addCommas(field?.value?.toFixed(2)) : field.value == null ? '-' : field.fieldName == 'generatedDate' ? convertDateTime(field.value) : addCommas(field.value) }}
                                            </span>
                                            <div v-if="field.type == 'bubble'" class="dynamic_bubble" :style="`background: ${field.bubbleColour};`">
                                                {{ field.value }}
                                            </div>
                                            <a :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null">
                                                <button class="btn btn-secondary w-100 text-center">
                                                    Document
                                                </button>
                                            </a>
                                            <a :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank">
                                                <button class="btn btn-primary w-100 text-center">
                                                    Document
                                                </button>
                                            </a>
                                            <button v-if="field.type == 'array'" class="btn btn-primary" @click="toggleImages(field.value)" data-bs-toggle="modal" data-bs-target="#imagesPopup">
                                                Show Images
                                            </button>
                                            <img v-if="field.type == 'image'" :src="field.value" class="table_image" alt="">
                                        </template>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <template v-for="(field, index) in tableFields" :key="index">
                                    <th v-if="field.type != 'hover'"  scope="col">
                                        <!-- <hr> -->
                                        {{ field.fieldName == 'payout' ? 'Total: ' + formatNumberInteger(sumAmount) : '' }}
                                    </th>
                                </template>
                                
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

        <div class="d-block d-xl-none mobile_cards_container">
            <template v-for="(row, index) in tableData" :key="index">
                <div class="card mb-3" @click="selectedTempJob = row" data-bs-toggle="modal" data-bs-target="#jobdatacard">
                    <div class="card-body">
                        <i class="bi bi-arrows-angle-expand expand_icon"></i>
                        <template v-for="(field, idx) in row" :key="idx">
                            <template v-if="idx < 3">
                                <b>
                                    <p>{{ field.title }}</p>
                                </b>
                                <p v-if="field.type != 'hover'" >
                                    <template v-if="tooltipExist(row,field)">
                                        <p class="cursor_pointer" v-if="field?.type == 'text'" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                            {{ field.value == null ? '-' : addCommas(field.value) }}
                                        </p>
                                        <div v-if="field.type == 'bubble'" class="dynamic_bubble cursor_pointer" :style="`background: ${field.bubbleColour};`" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                            {{ field.value }}
                                        </div>
                                        <a class="cursor_pointer" :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                            <button class="btn btn-secondary w-100 text-center">
                                                Document
                                            </button>
                                        </a>
                                        <a class="cursor_pointer" :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                            <button class="btn btn-primary w-100 text-center">
                                                Document
                                            </button>
                                        </a>
                                        <img v-if="field.type == 'image'" :src="field.value" class="table_image" alt="" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(row,field)">
                                    </template>
                                    <template v-else>
                                        <span v-if="field?.type == 'text'">
                                            {{ field?.fieldName == 'payout' ? field.value == null ? '-' : '£' + addCommas(field?.value?.toFixed(2)) : field.value == null ? '-' : field.fieldName == 'generatedDate' ? convertDateTime(field.value) : addCommas(field.value) }}
                                        </span>
                                        <div v-if="field.type == 'bubble'" class="dynamic_bubble" :style="`background: ${field.bubbleColour};`">
                                            {{ field.value }}
                                        </div>
                                        <a :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null">
                                            <button class="btn btn-secondary w-100 text-center">
                                                Document
                                            </button>
                                        </a>
                                        <a :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank">
                                            <button class="btn btn-primary w-100 text-center">
                                                Document
                                            </button>
                                        </a>
                                        <button v-if="field.type == 'array'" class="btn btn-primary" @click="toggleImages(field.value)" data-bs-toggle="modal" data-bs-target="#imagesPopup">
                                            Show Images
                                        </button>
                                        <img v-if="field.type == 'image'" :src="field.value" class="table_image" alt="">
                                    </template>
                                </p>
                            </template>
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>

    <div class="modal fade" id="jobdatacard" tabindex="-1" aria-labelledby="jobdatacardLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="jobdatacardLabel">Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <template v-for="(field, idx) in selectedTempJob" :key="idx">
                        <b>
                            <p>{{ field.title }}</p>
                        </b>
                        <p v-if="field.type != 'hover'" >
                            <template v-if="tooltipExist(selectedTempJob,field)">
                                <p class="cursor_pointer" v-if="field?.type == 'text'" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                                    {{ field.value == null ? '-' : addCommas(field.value) }}
                                </p>
                                <div v-if="field.type == 'bubble'" class="dynamic_bubble cursor_pointer" :style="`background: ${field.bubbleColour};`" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                                    {{ field.value }}
                                </div>
                                <a class="cursor_pointer" :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                                    <button class="btn btn-secondary w-100 text-center">
                                        Document
                                    </button>
                                </a>
                                <a class="cursor_pointer" :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                                    <button class="btn btn-primary w-100 text-center">
                                        Document
                                    </button>
                                </a>
                                <img v-if="field.type == 'image'" :src="field.value" class="table_image" alt="" data-bs-toggle="tooltip" data-bs-placement="top" :title="getTooltipValue(selectedTempJob,field)">
                            </template>
                            <template v-else>
                                <span v-if="field?.type == 'text'">
                                    {{ field?.fieldName == 'payout' ? field.value == null ? '-' : '£' + addCommas(field?.value?.toFixed(2)) : field.value == null ? '-' : field.fieldName == 'generatedDate' ? convertDateTime(field.value) : addCommas(field.value) }}
                                </span>
                                <div v-if="field.type == 'bubble'" class="dynamic_bubble" :style="`background: ${field.bubbleColour};`">
                                    {{ field.value }}
                                </div>
                                <a :href="'javascript:void(0)'" v-if="field.type == 'link' && field.value == null">
                                    <button class="btn btn-secondary w-100 text-center">
                                        Document
                                    </button>
                                </a>
                                <a :href="field.value" v-if="field.type == 'link' && field.value != null" target="_blank">
                                    <button class="btn btn-primary w-100 text-center">
                                        Document
                                    </button>
                                </a>
                                <button v-if="field.type == 'array'" class="btn btn-primary" @click="toggleImages(field.value)" data-bs-toggle="modal" data-bs-target="#imagesPopup">
                                    Show Images
                                </button>
                                <img v-if="field.type == 'image'" :src="field.value" class="table_image" alt="">
                            </template>
                        </p>
                    </template>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="noDataFound" class="p-0 p-xl-5 pt-0 pt-xl-0">
        <div class="alert alert-warning">
            Oops something went wrong, we're working on fixing this
        </div>
    </div>
    
    <div class="modal fade" id="imagesPopup" tabindex="-1" aria-labelledby="imagesPopupLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="imagesPopupLabel">Images</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <Carousel v-if="showImages">
                    <Slide v-for="(slide,index) in imagesCollection" :key="index">
                        <img class="d-block w-100" :src="slide" alt="slide 1"/>
                        <!-- {{ slide }} -->
                    </Slide>

                    <template #addons>
                        <Navigation />
                        <Pagination />
                    </template>
                </Carousel>
                <!-- {{ imagesCollection }} -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useMyStore } from '@/store';
    import 'bootstrap/dist/js/bootstrap.bundle.min.js';
    import axios from 'axios';
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    import { Calendar } from "vue-calendar-3";
    export default{
        data() {
            return {
                collection: [],
                tableFields: [],
                selectedTempJob: [],
                tableData: [],
                subTitle: '',
                myStore: useMyStore(),
                statusCollection: [],
                selectedStatus: '',
                selectedFrom: '',
                selectedTo: '',
                sumAmount: 0,
                sort_order: 'DESC',
                sortOptions: false,
                noDataFound: false,
                sortColumnsCollection: [
                    'Carrier Name',
                    'Generated Date',
                    'Number of Jobs',
                    'Payout Amount',
                ],
                selectedSortColumn: 'Generated Date',
                imagesCollection: [],
                showImages: false,
                breakpoints: {
                    700: {
                        itemsToShow: 1,
                        snapAlign: 'center',
                    },
                    // 1024 and up
                    1024: {
                        itemsToShow: 1,
                        snapAlign: 'start',
                    },
                },
                checkIn: null,
                checkOut: null,
                calendarPlaceholder: { checkIn: "From", checkOut: "To", },
                bookingColor: {
                    admin: "#7a37efa9",
                    contract: "#7a37efa9",
                },
                selectedMonths: [],
                default_selection: 'All',
            }
        },
        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
            Calendar,
        },
        async mounted() {
            // await axios.get('https://portal-litta-api.web.app/commercial/content').then(response=>{
            //     response.data.data.map(item=>{
            //         if (item.type == 'tab' && item.tag == 'litta_compliance_documents') {
            //             this.subTitle = item.subtitle;
            //         }
            //     });
            // }).catch(error=>{
            //     console.log('error: ', error);
            // });
            this.myStore.showFooter = false;
            this.fetchData();
            this.fetchThisMonthData();
            this.myStore.partner_dashboard.data.data.map((item)=>{
                if (item.type?.includes('tab') && item.tag == 'billing') {
                    this.subTitle = item.subtitle;
                }
            });
        },
        methods: {
            clearSort(){
                this.selectedSortColumn = 'Generated Date';
                this.sort_order = 'DESC';
                this.performSort();
            },
            convertDateTime(dateString) {
                let date = new Date(dateString);
                let formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
                let hours = date.getHours();
                let minutes = date.getMinutes();
                let ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12;
                let formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
                let formattedDateTime = `${formattedDate} ${formattedTime}`;
                return formattedDateTime;
            },
            toggleImages(val) {
                this.imagesCollection = [];
                this.showImages = false;
                this.imagesCollection = val;
                this.showImages = true;
                this.$forceUpdate();
                console.log(val);
            },
            performSort() {
                if (this.selectedSortColumn == 'Generated Date') {
                    this.sort(this.sort_order);
                    // this.sortByField('fleet_id');
                } else if (this.selectedSortColumn == 'Carrier Name') {
                    this.sortByField('carrierName');
                } else if (this.selectedSortColumn == 'Number of Jobs') {
                    this.sortByField('numberOfJobs');
                } else if (this.selectedSortColumn == 'Payout Amount') {
                    this.sortByField('payout');
                }
            },
            sortByField(fieldName) {
                this.tableData.sort((a, b) => {
                    const valueA = this.getFieldValue(a, fieldName);
                    const valueB = this.getFieldValue(b, fieldName);

                    if (valueA === null || valueB === null) {
                        // Handle null values by placing them at the end
                        return valueA === null ? 1 : -1;
                    }

                    if (typeof valueA === 'string' && typeof valueB === 'string') {
                        // Sort alphabetically in descending order (from Z to A)
                        return valueB.localeCompare(valueA);
                    }

                    if (this.sort_order === 'ASC') {
                        return valueA - valueB;
                    } else {
                        return valueB - valueA;
                    }
                });
            },
            getFieldValue(record, fieldName) {
                const field = record?.find(item => item.fieldName === fieldName);
                return field ? field.value : null;
            },
            sort(val) {
                this.sort_order = val;
                this.sortOptions = false;

                this.tableData.sort((a, b) => {
                    const dateA = this.parseDateTime(a);
                    const dateB = this.parseDateTime(b);

                    if (this.sort_order === 'ASC') {
                        return dateA - dateB;
                    } else {
                        return dateB - dateA;
                    }
                });

                console.log("Dates of the first 10 records after sorting:");
            },

            parseDateTime(record) {
                // const dateField = record.find(item => item.fieldName === 'completionDate');
                // const timeField = record.find(item => item.fieldName === 'completionTime');

                // if (!dateField || !timeField || !dateField.value || !timeField.value) {
                //     // If either date or time value is missing, return a date far in the future
                //     return this.sort_order === 'ASC' ? new Date('9999-12-31T23:59:59') : new Date('0000-01-01T00:00:00');
                // }

                // const dateValue = dateField.value;
                // const timeValue = timeField.value;

                // const [day, month, year] = dateValue.split('/');
                // const [hours, minutes, seconds] = timeValue.split(':');

                // return new Date(year, month - 1, day, hours, minutes, seconds);

                const generatedDateTimeField = record?.find(item => item.fieldName === 'generatedDate');

                if (generatedDateTimeField) {
                    const dateTimeValue = generatedDateTimeField.value;
                    const dateTime = new Date(dateTimeValue);
                    console.log('converted date:', dateTime);
                    return dateTime;
                }
            },
            async fetchData() {
                if (this.myStore.partnerBilling == null) {
                    let user_details = localStorage.getItem('commercials_user_data');
                    user_details = JSON.parse(user_details);
                    console.log('user details: -> ', user_details);
                    let user_id = user_details.uid;
                    // await axios.get(`https://portal-litta-api.web.app/partners/users/fak7swdi7SW3bWqSTcsPv6528SZ2/billing`)
                    await axios.get(`https://portal-litta-api.web.app/partners/users/${user_id}/billing`)
                    .then(response => {
                        // this.processData(response.data.data);
                        this.myStore.partnerBilling = response;
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
                }
                if (this.myStore.partnerBilling) {
                    this.processData(this.myStore.partnerBilling?.data.data);
                    this.performSort();
                } else {
                    this.noDataFound = true;
                }
                this.$emit('triggerFunction');
            },
            processData(data) {
                this.tableFields = data[0].map(item => item);
                this.tableData = data.map(row => row.map(item => item));
                this.sort(this.sort_order);
                data.map((item)=>{
                    item.map((item2)=>{
                        if (item2.fieldName == 'emailStatus') {
                            if (!this.statusCollection.includes(item2.value)){
                                this.statusCollection.push(item2.value);
                            }
                        }
                    });
                });
                this.sumAmount = 0;
                data.map(item => {
                    const tempAmount = item.find(item2 => item2.fieldName === 'payout');
                    if (tempAmount.value != null) {
                        this.sumAmount += tempAmount.value; 
                    }
                });
            },
            clearFilter() {
                this.selectedStatus = '';
                this.selectedFrom = '';
                this.selectedTo = '';
                this.checkIn = null;
                this.checkOut = null;
                this.processData(this.myStore.partnerBilling.data.data);
                this.sumAmount = 0;
                this.myStore.partnerBilling.data.data.map(item => {
                    const tempAmount = item.find(item2 => item2.fieldName === 'payout');
                    if (tempAmount.value != null) {
                        this.sumAmount += tempAmount.value; 
                    }
                });
                this.performSort();
            },
            performFilter(){
                let temp_data = [];
                if (this.selectedStatus == '') {
                    temp_data = this.myStore.partnerBilling.data.data;
                } else if (this.selectedStatus != '') {
                    this.myStore.partnerBilling.data.data.map((item)=>{
                        item.map((item2)=>{
                            if (item2.fieldName == 'emailStatus' && this.selectedStatus == item2.value) {
                                temp_data.push(item);
                            }
                        });
                    });
                } 
                if (this.checkIn && this.checkOut) {
                    const fromDate = new Date(this.checkIn);
                    const toDate = new Date(this.checkOut);
                    const filteredData = temp_data.filter(item => {
                        const completionDate = item?.find(item2 => item2.fieldName === 'generatedDate');
                        if (completionDate) {
                            if (completionDate.value != null) {
                                const itemDate = this.parseDateTime(item);
                                return itemDate >= fromDate && itemDate <= toDate;
                            }
                        }
                        return false;
                    });
                    temp_data = filteredData;
                }
                if (this.selectedFrom != '' && this.selectedTo != '') {
                    const fromDate = new Date(this.selectedFrom);
                    const toDate = new Date(this.selectedTo);
                    const filteredData = temp_data.filter(item => {
                        const completionDate = item?.find(item2 => item2.fieldName === 'generatedDate');
                        if (completionDate) {
                            if (completionDate.value != null) {
                                const itemDate = this.parseDateTime(item);
                                return itemDate >= fromDate && itemDate <= toDate;
                            }
                        }
                        return false;
                    });
                    temp_data = filteredData;
                }
                this.sumAmount = 0;
                temp_data.map(item => {
                    const tempAmount = item.find(item2 => item2.fieldName === 'payout');
                    if (tempAmount.value != null) {
                        this.sumAmount += tempAmount.value; 
                    }
                });
                this.tableData = temp_data;
                // document.getElementById('jobsFilter').style.display = 'none';
                // const modalBackdrops = document.querySelectorAll('.modal-backdrop');
                // modalBackdrops.forEach(modalBackdrop => {
                //     modalBackdrop.remove();
                // });
                // console.log(temp_data);
                this.performSort();
            },
            parseDate(dateString) {
                const parts = dateString.split('/');
                return new Date(parts[2], parts[1] - 1, parts[0]);
            },
            toHumanReadable(fieldName) {
                return fieldName.split(/(?=[A-Z])/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            },
            formatNumber(val) {
                // Remove any non-numeric characters
                let value = val.toString().replace(/[^0-9.]/g, '');

                // Split the number into integer and decimal parts
                let parts = value.split('.');
                let integerPart = parts[0];
                let decimalPart = parts[1] || '';

                // Add commas to the integer part
                integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                // Limit the decimal part to 2 digits
                decimalPart = decimalPart.padEnd(2, '0').slice(0, 2);

                // Concatenate the integer and decimal parts with a decimal point
                value = integerPart + (decimalPart ? '.' + decimalPart : '');

                // Update the formatted value
                return value;
            },
            formatNumberInteger(val) {
                // Remove any non-numeric characters
                let value = val.toString().replace(/[^0-9.]/g, '');
                let parts = value.split('.');
                let integerPart = parts[0];
                let decimalPart = parts[1] || '';
                // Add commas to the integer part
                integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                decimalPart = decimalPart.padEnd(2, '0').slice(0, 2);
                if(val > 0) {
                    return '£' + integerPart + (decimalPart ? '.' + decimalPart : '');
                } else {
                    return integerPart;
                }
                // return integerPart;
            },
            addCommas(value) {
                if (!isNaN(value) && typeof value === 'number') {
                    return value.toLocaleString();
                } else {
                    return value;
                }
            },
            checkStatus(row) {
                let temp_data = row.find(item=>item.fieldName == 'status');
                if (temp_data.value == 'Cancelled') {
                    return true;
                } else {
                    false;
                }
            },
            tooltipExist(row,field){
                let temp_condition = false; 
                row.map((item)=>{
                    if (item.type == 'hover' && item.onHover == field.fieldName) {
                        temp_condition = true;
                    }
                });
                return temp_condition;
            },
            getTooltipValue(row,field) {
                let temp_condition = 'Testing'; 
                row.map((item)=>{
                    if (item.type == 'hover' && item.onHover == field.fieldName) {
                        temp_condition = item.value;
                    }
                });
                return temp_condition;
            },
            fetchThisMonthData() {
                const currentDate = new Date();
                // Array of month names
                const monthNames = ["January", "February", "March", "April", "May", "June",
                                    "July", "August", "September", "October", "November", "December"];
                // Get current month's full name
                const currentMonthFullName = monthNames[currentDate.getMonth()];
                // Get current year
                const currentYear = currentDate.getFullYear();
                console.log("Current month full name:", currentMonthFullName);
                console.log("Current full year:", currentYear);

                this.selectedMonths = [];
                this.selectedMonths.push(currentMonthFullName);
                this.default_selection = currentYear;

                this.checkIn = new Date(currentYear, currentDate.getMonth(), 1).toISOString();
                this.checkOut = new Date(currentYear, currentDate.getMonth() + 1, 0, 23, 59, 59).toISOString();
            },
            lastMonthData() {
                const currentDate = new Date();
                let lastMonthMonth = currentDate.getMonth() - 1;
                let lastMonthYear = currentDate.getFullYear();
                if (lastMonthMonth === -1) {
                    lastMonthMonth = 11;
                    lastMonthYear -= 1;
                }
                const monthNames = ["January", "February", "March", "April", "May", "June",
                                    "July", "August", "September", "October", "November", "December"];
                const lastMonthFullName = monthNames[lastMonthMonth];
                console.log("Last month full name:", lastMonthFullName);
                console.log("Last month year:", lastMonthYear);

                this.selectedMonths = [];
                this.selectedMonths.push(lastMonthFullName);
                this.default_selection = lastMonthYear;

                this.checkIn = new Date(lastMonthYear, lastMonthMonth, 1).toISOString();
                this.checkOut = new Date(lastMonthYear, lastMonthMonth + 1, 0, 23, 59, 59).toISOString();
            },
            fetchThisQuarterData() {
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth();
                const currentQuarter = Math.floor(currentMonth / 3); // Get the current quarter (0, 1, 2, or 3)

                const startMonth = currentQuarter * 3;
                const endMonth = startMonth + 2;

                const currentYear = currentDate.getFullYear();
                const quarterStart = new Date(currentYear, startMonth, 1).toISOString();
                const quarterEnd = new Date(currentYear, endMonth + 1, 0, 23, 59, 59).toISOString();

                this.checkIn = quarterStart;
                this.checkOut = quarterEnd;
            },

            lastQuarterData() {
                const currentDate = new Date();
                let currentMonth = currentDate.getMonth();
                let currentYear = currentDate.getFullYear();
                
                let lastQuarter = Math.floor(currentMonth / 3) - 1; // Get the last quarter (0, 1, 2, or 3)

                if (lastQuarter < 0) {
                    lastQuarter = 3;
                    currentYear -= 1;
                }

                const startMonth = lastQuarter * 3;
                const endMonth = startMonth + 2;

                const quarterStart = new Date(currentYear, startMonth, 1).toISOString();
                const quarterEnd = new Date(currentYear, endMonth + 1, 0, 23, 59, 59).toISOString();

                this.checkIn = quarterStart;
                this.checkOut = quarterEnd;
            },

            fetchThisYearData() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();

                const yearStart = new Date(currentYear, 0, 1).toISOString();
                const yearEnd = new Date(currentYear, 11, 31, 23, 59, 59).toISOString();

                this.checkIn = yearStart;
                this.checkOut = yearEnd;
            },
            checkThisQuarterSelected() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth();
                const currentQuarter = Math.floor(currentMonth / 3);

                const startMonth = currentQuarter * 3;
                const endMonth = startMonth + 2;

                const quarterStart = new Date(currentYear, startMonth, 1);
                const quarterEnd = new Date(currentYear, endMonth + 1, 0, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Check if the entire quarter is selected
                return checkInDate.getTime() === quarterStart.getTime() && 
                    checkOutDate.getTime() === quarterEnd.getTime();
            },

            checkLastQuarterSelected() {
                const currentDate = new Date();
                let currentMonth = currentDate.getMonth();
                let currentYear = currentDate.getFullYear();
                let lastQuarter = Math.floor(currentMonth / 3) - 1;

                if (lastQuarter < 0) {
                    lastQuarter = 3;
                    currentYear -= 1;
                }

                const startMonth = lastQuarter * 3;
                const endMonth = startMonth + 2;

                const quarterStart = new Date(currentYear, startMonth, 1);
                const quarterEnd = new Date(currentYear, endMonth + 1, 0, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Check if the entire last quarter is selected
                return checkInDate.getTime() === quarterStart.getTime() && 
                    checkOutDate.getTime() === quarterEnd.getTime();
            },

            checkThisYearSelected() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();

                const yearStart = new Date(currentYear, 0, 1);
                const yearEnd = new Date(currentYear, 11, 31, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Check if the entire year is selected
                return checkInDate.getTime() === yearStart.getTime() && 
                    checkOutDate.getTime() === yearEnd.getTime();
            },
            checkMonthSelected() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth(); // 0 = January, 1 = February, ...

                // Current month start and end (normalize to ignore time)
                const currentMonthStart = new Date(currentYear, currentMonth, 1);
                const currentMonthEnd = new Date(currentYear, currentMonth + 1, 0, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Normalize check-in and check-out dates to midnight for comparison
                const normalizedCheckIn = new Date(checkInDate.getFullYear(), checkInDate.getMonth(), checkInDate.getDate());
                const normalizedCheckOut = new Date(checkOutDate.getFullYear(), checkOutDate.getMonth(), checkOutDate.getDate(), 23, 59, 59);

                console.log("Current month start:", currentMonthStart);
                console.log("Current month end:", currentMonthEnd);
                console.log("Normalized check-in date:", normalizedCheckIn);
                console.log("Normalized check-out date:", normalizedCheckOut);

                if (normalizedCheckIn >= currentMonthStart && normalizedCheckOut <= currentMonthEnd) {
                    return true;
                } else {
                    console.log('Check-in or check-out is outside the current month range');
                    return false;
                }
            },

            checkLastMonthSelected() {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const currentMonth = currentDate.getMonth()-1; // 0 = January, 1 = February, ...

                // Current month start and end (normalize to ignore time)
                const currentMonthStart = new Date(currentYear, currentMonth, 1);
                const currentMonthEnd = new Date(currentYear, currentMonth + 1, 0, 23, 59, 59);

                const checkInDate = new Date(this.checkIn);
                const checkOutDate = new Date(this.checkOut);

                // Normalize check-in and check-out dates to midnight for comparison
                const normalizedCheckIn = new Date(checkInDate.getFullYear(), checkInDate.getMonth(), checkInDate.getDate());
                const normalizedCheckOut = new Date(checkOutDate.getFullYear(), checkOutDate.getMonth(), checkOutDate.getDate(), 23, 59, 59);

                console.log("Current month start:", currentMonthStart);
                console.log("Current month end:", currentMonthEnd);
                console.log("Normalized check-in date:", normalizedCheckIn);
                console.log("Normalized check-out date:", normalizedCheckOut);

                if (normalizedCheckIn >= currentMonthStart && normalizedCheckOut <= currentMonthEnd) {
                    return true;
                } else {
                    console.log('Check-in or check-out is outside the current month range');
                    return false;
                }
            },
        }
    }
</script>

<style scoped>
    .table_header{
        background: #1E7F78;
        color: #fff;
    }

    .main_text{
        font-family: poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.68px;
        text-align: left;
    }
    

    .table_header p{
        font-size: 20px;
        font-family: poppins;
        font-weight: 800;
        margin-bottom: 0;
    }

    .table_header span{
        align-items: center;
    }

    .align_contents_center{
        align-content: center;
        align-items: center;
    }

    .btn-white{
        background-color: #fff;
    }

    .company_name{
        background-color: #E9EEF9;
        color: #000;
        padding: 5px 20px;
        width: fit-content;
        border-radius: 10PX;
    }

    table { border-collapse: collapse; width: 100%; }
    th, td { background: #fff; padding: 8px 16px; }


    .tableFixHead {
        overflow: auto;
        height: 70vh;
    }

    .tableFixHead thead th {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #eeeeee;
    }

    .tableFixHead tfoot th {
        position: sticky;
        bottom: -5px;
        z-index: 1; /* Ensure the footer is on top of other content */
        background-color: #eeeeee;
    }

    th {
        white-space: nowrap;
    }

    i.text-success{
        font-weight: 900;
        font-size: 24px;
    }

    .dynamic_bubble{
        color: #fff;
        padding: 5px 10px;
        text-align:center;
        border-radius: 10px;
    }

    .btn-custom{
        background-color: #fff;
        border: 1px solid #000;
    }

    .cursor_pointer{
        cursor: pointer;
    }

    .table_image{
        width: 50px;
    }

    .mobile_cards_container{
        overflow: auto;
        height: 70vh;
    }

    .mobile_cards_container .card-body{
        position: relative;
    }

    .mobile_cards_container .expand_icon{
        position: absolute;
        right: 10px;
        top: 10px;
    }

    b p{
        margin-bottom: 0;
    }

    @media(max-width:764px){
        .table_header p{
            font-size: 16px;
        }
    }

    .modal-width {
        width: 60vw;
        max-width: 60vw;
    }

    @media (max-width: 764px) {
        .modal-width {
            width: auto;
            max-width: none;
        }
    }
    .filter_time_options li:hover{
        cursor: pointer;
    }

    .filter_time_options li.active{
        background-color: #7B37EF;
        border: 1px solid #7B37EF;
    }
    #jobsFilter .btn-primary{
        background-color: #7B37EF !important;
        border: 1px solid #7B37EF;
    }
</style>