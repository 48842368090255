import { createPinia } from 'pinia';
import { defineStore } from 'pinia';
import axios from 'axios';

// Create a new Pinia instance
export const pinia = createPinia();

// Export the Pinia store
export const useMyStore = defineStore({
    id: 'myStore',
    // Define your state, getters, mutations, actions, etc.
    state: () => ({
        summary: null,
        company_info: null,
        jobs: null,
        info: null,
        billing: null,
        info_title: null,
        metrics_title: null,
        jobs_title: null,
        documents_title: null,
        payments_title: null,
        valid_user: null,
        partner_valid_user: null,
        showFooter: true,
        partner_dashboard: null,
        main_dashboard: null,
        partnerJobs: null,
        partner_company_info: null,
        commercial_permissions: null,
        partnerDrivers: null, 
        partnerBilling: null,
        commercialCompanyName: null,
        partnerCompanyName: null,
        partnerSummary: null,
        partnerVehicles: null,
        bookingLoads: null,
        bookingHazardousItems: null,
        bookingUserId: null,
        partnerQueryItemsLoads: null,
    }),
    actions: {

        // setters
        setSummary(val){
            this.summary = val;
        },
        setCompanyInfo(val){
            this.company_info = val;
        },
        setJobs(val){
            this.jobs = val;
        },
        setInfo(val){
            this.info = val;
        },
        setContent(val){
            this.content = val;
        },
        setValidUser(val){
            this.valid_user = val;
        },
        setInfoTitle(val){
            this.info_title = val;
        },
        setMetricsTitle(val){
            this.metrics_title = val;
        },
        setJobsTitle(val){
            this.jobs_title = val;
        },
        setDocumentsTitle(val){
            this.documents_title = val;
        },
        setPaymentsTitle(val){
            this.payments_title = val;
        },
        setBillingSData(val){
            this.billing = val;
        },
        setPartnerDashBoard(val){
            this.partner_dashboard = val;
        },
        setPartnerJobs(val){
            this.setPartnerJobs = val;
        },
        fetchPartnerCompanyName(){
            let user_details = localStorage.getItem('commercials_user_data');
            user_details = JSON.parse(user_details);
            console.log('user details pinia: -> ', user_details);
            let user_id = user_details.uid;
            axios.get(`https://portal-litta-api.web.app/partners/users/${user_id}/info`).then(response=>{
                if (response.data.data.length > 0) {
                    let company_info_temp = response.data.data.filter(item => item.section == 'companyInformation');
                    console.log('company information:', company_info_temp);
                    if (company_info_temp) {
                        this.partnerCompanyName = company_info_temp[0].fields.find(item2=> item2.fieldName == 'companyName').value;
                    }
                }
            }).catch(error=>{
                console.log('error', error);
            });
        },

        // getters
    },
});