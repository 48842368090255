<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
    <div class="container-fluid">
      <div class="d-flex" id="top_header_logo_name">
        <router-link class="navbar-brand" to="/partner/dashboard">
          <img src="@/assets/website_logo_partners.svg" class="website_logo" alt="" />
        </router-link>
        <p class="company_name ps-md-5 d-none d-lg-block">
          {{ partnerCompanyName }}
        </p>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" @click="hamburger = !hamburger"></span>
      </button>
      <div
        class="collapse navbar-collapse"
        :class="hamburger == true ? 'show' : ''"
        id="navbarSupportedContente"
      >
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <!-- <li class="nav-item me-lg-3">
                        <div class="dropdown mt-3 mt-lg-0 mb-2">
                            <button class="booking_form w-100" @click="createEnquiry">
                                Create an enquiry
                            </button>
                        </div>
                    </li> -->
          <li class="nav-item me-lg-3">
            <div class="dropdown me-2 mb-2">
              <button
                class="help_button w-100 me-2"
                :title="titleText"
                @click="helpOption = !helpOption"
              >
                Contact
              </button>
              <ul class="dropdown-menu mt-2" :class="helpOption == true ? 'show' : ''">
                <li>
                  <a class="dropdown-item" :href="'tel:' + phoneNumber"
                    >Phone: {{ phoneNumber }}</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" :href="'mailto:' + email"
                    >Email: {{ email }}</a
                  >
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <div class="dropdown">
              <button class="logout_button w-100" @click="logout">LOGOUT</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { useMyStore } from "@/store";
import axios from "axios";
export default {
  data() {
    return {
      myStore: useMyStore(),
      phoneNumber: "",
      email: "",
      titleText: "",
      helpOption: false,
      hamburger: false,
    };
  },
  async mounted() {
    if (this.myStore.partnerCompanyName == null) {
      this.myStore.fetchPartnerCompanyName();
    }
    if (this.myStore.partner_dashboard == null) {
      await axios
        .get("https://portal-litta-api.web.app/partners/content")
        .then((response) => {
          this.myStore.partner_dashboard = response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    this.myStore.partner_dashboard.data.data.map((item) => {
      console.log(item);
      if (item.type) {
        if (item.type[0] == "footer" && item.tag == "supply_dashboard_phone") {
          this.phoneNumber = item.content;
        }
        if (item.type[0] == "footer" && item.tag == "supply_dashboard_email") {
          this.email = item.content;
        }
      }
    });

    this.titleText = "Phone: " + this.phoneNumber + ", " + "Email: " + this.email;
  },
  computed: {
    partnerCompanyName() {
      // Access the store value
      return this.myStore.partnerCompanyName;
    },
  },
  methods: {
    logout() {
      // Remove user details from local storage
      localStorage.removeItem("commercials_user_data");

      // Redirect the user to the login page
      this.$router.push("/partner/login");
    },
    createEnquiry() {
      this.$router.push("/partner/book");
    },
  },
};
</script>

<style scoped>
.logout_button {
  background: #ffe7e0;
  color: #d41866;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 50px;
  border: none;
}

.help_button {
  background: #c5c5c5;
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 50px;
  border: none;
}

.company_name_col {
  align-content: center;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  right: 0;
}

.align_content_center .dropdown-menu {
  left: 0;
}

.hamburger {
  background-color: #ffffff;
  color: #000;
  border: none;
  height: 40px;
  width: 40px;
}

.hamburger i {
  font-size: 28px;
  color: #7b37ef;
}

.company_name {
  font-family: Poppins;
  font-size: 28px;
  font-weight: 800;
  line-height: 31.68px;
  text-align: left;
  margin-bottom: 0;
  align-content: center;
}

.align_content_center {
  align-content: center;
}

.booking_form {
  background-color: #7b37ef;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 50px;
  border: none;
}

@media (max-width: 764px) {
  .website_logo {
    height: 40px;
    width: 150px;
  }
}
</style>
