<template>
    <div class="row p-0 p-xl-5 pt-0 pt-xl-0">
        <div class="col-12 col-xl-7 col-xxl-8">
            <!-- <p class="main_text ps-1">
                {{ subTitle }}
            </p> -->
            <div class="card custom_card p-3">
                <h2 class="card_title mb-3">
                    LITTA APP LIMITED
                </h2>

                <table class="mt-3">
                    <tbody>
                        <tr v-for="(data,index) in endpointData" :key="index">
                            <td>
                                <p class="mb-3">
                                    {{ data.title }}
                                </p>
                            </td>

                            <td class="d-flex">
                                <p class="mb-3">
                                    {{ data.value }}
                                </p>
                                <a :href="data.url" v-if="data.url" target="_blank" class="ms-2">
                                    <img src="@/assets/compliance/new_tab.png" alt="">
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="col-12 col-xl-5 col-xxl-4 d-flex p-xl-5 pt-xl-0 pt-3">
            <div class="w-auto mx-auto my-auto">
                <iframe style="border: 1px solid #dddddd; overflow: hidden" width="330" height="209" src="https://environment.data.gov.uk/public-register/waste-carriers-brokers/widget/CBDU375738" title="Widget for CBDU375738"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                endpointData: [],
                subTitle: '',
            }
        },
        async mounted() {
            // await axios.get('https://portal-litta-api.web.app/commercial/content').then(response=>{
            //     response.data.data.map(item=>{
            //         if (item.type == 'tab' && item.tag == 'litta_compliance_documents') {
            //             this.subTitle = item.subtitle;
            //         }
            //     });
            //     }).catch(error=>{
            //     console.log('error: ', error);
            // });
            let temp_obj = {
                title: 'Document Title1',
                value: 'Something something',
            };

            let temp_obj2 = {
                title: 'Document Title1',
                value: 'Something something',
                url: 'https://google.com', 
            };

            for (let i=0; i<=5; i++) {
                if (i%2 == 0) {
                    this.endpointData.push(temp_obj2);
                } else {
                    this.endpointData.push(temp_obj);
                }
            }

            this.$emit('triggerFunction');
        },
    }
</script>

<style scoped>
    .main_text{
        font-family: poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.68px;
        text-align: left;
    }

    .main_text span{
        color: #06CCCC;
    }

    .card_title{
        font-family: poppins;
        font-size: 22px;
        font-weight: 800;
        line-height: 23.68px;
        text-align: left;
        margin-bottom: 0;
    }

    .card_content{
        font-family: poppins;
        font-size: 24px;
        font-weight: 600;
        line-height: 31.68px;
        text-align: left;
    }

    .custom_card{
        border-radius: 12px;
        border: none;
    }

    .options_drop_down{
        background-color: #F7FAFC;
        padding: 5px 10px;
        border-radius: 10px;
        cursor: pointer;
        border: none;
    }

    .right_container{
        /* border-bottom: 10px solid #48A049; */
    }

    .bg_dark{
        background-color: #000;
        border-bottom: 10px solid #48A049;
    }

    .w_50{
        width: 90%;
        margin: auto;
    }

    @media (min-width: 1200px) {
        .w_50{
            width: 60%;
            margin: auto;
        }
    }

    .left_text{
        font-size: 18px;
        font-weight: 900;
    }

    .right_text{
        font-size: 18px;
        font-weight: 600;
    }

    .right_link{
        font-size: 18px;
        font-weight: 600;
        color: #4B7DB4;
    }

    .border_radius_10{
        border-radius: 20px;
        overflow: hidden;
        border: 1px solid black;
    }

    .litta_title{
        font-family: poppins;
        font-size: 16px;
        font-weight: 700;
        line-height: 23.68px;
        text-align: left;

    }
</style>