import { createRouter, createWebHistory } from 'vue-router';

import Home from '@/views/Commercials/HomePage.vue';
import CommercialMetrics from '@/views/Commercials/CommercialMetrics.vue';
import CommercialJobs from '@/views/Commercials/CommercialJobs.vue';
import CommercialBilling from '@/views/Commercials/CommercialBilling.vue';
import CommercialCompliance from '@/views/Commercials/CommercialCompliance.vue';
import CommercialInfo from '@/views/Commercials/CommercialInfo.vue';
import CommercialLogin from './views/Commercials/LoginPage.vue';
// import CommercialSignup from './views/Commercials/SignupPage.vue';
import CommercialForgot from '@/views/Commercials/ForgotPassword.vue';
import CommercialNotvalidUser from '@/views/Commercials/NotvalidUser.vue';
import CommercialBooking from '@/views/Commercials/CommercialBooking.vue';

import PartnerDashboard from '@/views/Partners/PartnerDashboard.vue';
import PartnerLogin from './views/Partners/LoginPage.vue';
// import PartnerSignup from './views/Partners/SignupPage.vue';
import PartnerForgot from '@/views/Partners/ForgotPassword.vue';
import PartnerNotvalidUser from '@/views/Partners/PartnerNotValidUser.vue';
import PartnerBilling from '@/views/Partners/PartnerBilling.vue';
import PartnerDrivers from '@/views/Partners/PartnerDrivers.vue';
import PartnerInfo from '@/views/Partners/PartnerInfo.vue';
import PartnerJobs from '@/views/Partners/PartnerJobs.vue';
import PartnerMetrics from '@/views/Partners/PartnerMetrics.vue';
import PartnerVehicles from '@/views/Partners/PartnerVehicles.vue';
import PartnerCompliance from '@/views/Partners/PartnerCompliance.vue';
import PartnerVideos from '@/views/Partners/PartnerVideos.vue';

import LittaJobs from '@/views/LittaJobs.vue';
import ComplianceDocuments from '@/views/ComplianceDocuments.vue';
import AccountInfo from '@/views/AccountInfo.vue';

const routes = [
    { path: '/', component: Home },
    { path: '/commercial/dashboard', component: Home, meta: {title: 'Commercial Dashboard'}},
    { path: '/commercial', component: Home, meta: {title: 'Commercial Dashboard'}},
    { path: '/commercial/metrics', component: CommercialMetrics, meta: {title: 'Commercial Metrics'}},
    { path: '/commercial/jobs', component: CommercialJobs, meta: {title: 'Commercial Jobs'}},
    { path: '/commercial/information', component: CommercialInfo, meta: {title: 'Commercial Information'}},
    { path: '/commercial/billing', component: CommercialBilling, meta: {title: 'Commercial Billing'}},
    { path: '/commercial/compliance', component: CommercialCompliance, meta: {title: 'Commercial Compliance'}},
    { path: '/commercial/login', component:  CommercialLogin, meta: {title: 'Commercial Login'}},
    // { path: '/commercial/signup', component: CommercialSignup, meta: {title: 'Commercial Signup'} },
    { path: '/commercial/forgot-password', component: CommercialForgot, meta: {title: 'Commercial Forgot Password'} },
    { path: '/commercial/user-notvalid', component: CommercialNotvalidUser, meta: {title: 'Commercial Not Valid User'} },
    { path: '/commercial/book', component: CommercialBooking, meta: {title: 'Commercial Booking Form'}},

    { path: '/partner/dashboard', component:  PartnerDashboard, meta: {title: 'Partner Dashboard'}},
    { path: '/partner', component:  PartnerDashboard, meta: {title: 'Partner Dashboard'}},
    { path: '/partner/login', component:  PartnerLogin, meta: {title: 'Partner Login'}},
    // { path: '/partner/signup', component: PartnerSignup, meta: {title: 'Partner Signup'} },
    { path: '/partner/forgot-password', component: PartnerForgot, meta: {title: 'Partner Forgot Password'} },
    { path: '/partner/user-notvalid', component: PartnerNotvalidUser, meta: {title: 'Partner Not Valid User'} },
    { path: '/partner/billing', component:  PartnerBilling, meta: {title: 'Partner Billing'}},
    { path: '/partner/drivers', component:  PartnerDrivers, meta: {title: 'Partner Drivers'}},
    { path: '/partner/info', component:  PartnerInfo, meta: {title: 'Partner Info'}},
    { path: '/partner/jobs', component:  PartnerJobs, meta: {title: 'Partner Jobs'}},
    { path: '/partner/metrics', component:  PartnerMetrics, meta: {title: 'Partner Metrics'}},
    { path: '/partner/vehicles', component:  PartnerVehicles, meta: {title: 'Partner Vehicles'}},
    { path: '/partner/compliance', component:  PartnerCompliance, meta: {title: 'Partner Compliance'}},
    { path: '/partner/videos', component:  PartnerVideos, meta: {title: 'Partner Videos'}},

    { path: '/litta-jobs', component: LittaJobs },
    { path: '/compliance-documents', component: ComplianceDocuments },
    { path: '/account-info', component: AccountInfo },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach(async (to, from, next) => {
    // Preserve demo query parameter if it exists
    const demoValue = from.query.demo;
    const hasQueryParams = Object.keys(to.query).length > 0;
    
    const nextRoute = demoValue !== undefined && !hasQueryParams ? 
        { path: to.path, query: { ...to.query, demo: demoValue } } : 
        undefined;

    const isLoggedIn = localStorage.getItem('commercials_user_data');
    
    if (to.meta && to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = 'commercial-by-litta';
    }

    if (to.path === '/'){
        return next(nextRoute || '/commercial/dashboard');
    }

    if (to.path === '/commercial/login' || to.path === '/commercial/signup' || to.path === '/commercial/forgot-password') {
        if (isLoggedIn) {
            return next(nextRoute || '/commercial/dashboard');
        }
        return next(nextRoute);
    }
    
    if (to.path === '/partner/login' || to.path === '/partner/signup' || to.path === '/partner/forgot-password') {
        if (isLoggedIn) {
            return next(nextRoute || '/partner');
        }
        return next(nextRoute);
    }

    if (
        (
            to.path === '/commercial/dashboard' ||
            to.path === '/commercial' ||
            to.path === '/commercial/metrics' ||
            to.path === '/commercial/jobs' ||
            to.path === '/commercial/information' ||
            to.path === '/commercial/billing' ||
            to.path === '/commercial/compliance' ||
            to.path === '/commercial/book'
        ) && isLoggedIn
    ) {
        return next(nextRoute);
    } else if (to.path.startsWith('/commercial')) {
        return next(nextRoute || '/commercial/login');
    }

    if (
        (
            to.path === '/partner' ||
            to.path === '/partner/dashboard' ||
            to.path === '/partner/billing' ||
            to.path === '/partner/drivers' ||
            to.path === '/partner/info' ||
            to.path === '/partner/jobs' ||
            to.path === '/partner/metrics' ||
            to.path === '/partner/vehicles' ||
            to.path === '/partner/videos'
        ) && isLoggedIn
    ) {
        return next(nextRoute);
    } else {
        return next(nextRoute || '/partner/login');
    }
});

export default router;