<template>
    <!-- <h2 class="card_title p-3 p-xl-5 pb-0 pb-xl-0 pt-0 pt-xl-0">
        Account Details
    </h2> -->
    <div class="row p-0 p-xl-5 pt-0 pt-xl-0 d-flex" v-if="!noDataFound">
        <div class="col-12 col-xl-8">
            <!-- <p class="main_text ps-1">
                {{ subTitle }}
            </p> -->
            <div
                class="card custom_card p-3 w-100 mb-3"
                v-for="(data, index) in endpointData"
                :key="index"
            >
                <h2 class="card_title">
                    {{ data.sectionName }}
                </h2>
                <hr />
                <template v-for="(field, index) in data.fields" :key="index">
                    <div
                        class="row mb-3"
                        v-if="field.fieldName != 'clientType'"
                    >
                        <div class="col-6">
                            {{ getLabel(field.title) }}
                        </div>

                        <div
                            class="col-6 text_right"
                            :class="{
                                'demo-blur':
                                    this.$route.query.demo !== undefined &&
                                    (field.fieldName !== 'entityType' &&
                                        field.fieldName !== 'accountManager' &&
                                        field.fieldName !== 'accountManagerEmail'),
                            }"
                        >
                            <span>
                                {{
                                    field.title.includes("£")
                                        ? getLabel3(field.title)
                                        : ""
                                }}
                            </span>
                            <span v-if="Array.isArray(field.value)">
                                {{ field.value.join(", ") }}
                            </span>
                            <span v-else>
                                {{ addCommas(field.value) }}
                            </span>
                            <span>
                                {{
                                    field.title.includes("£")
                                        ? ""
                                        : getLabel2(field.title)
                                }}
                            </span>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>

    <div v-if="noDataFound" class="p-0 p-xl-5 pt-0 pt-xl-0">
        <div class="alert alert-warning">
            Oops something went wrong, we're working on fixing this
        </div>
    </div>
</template>

<script>
import { useMyStore } from "@/store";
import axios from "axios";
export default {
    data() {
        return {
            endpointData: [],
            subTitle: "",
            myStore: useMyStore(),
            noDataFound: false,
        };
    },
    methods: {
        getLabel(key) {
            const index = key.indexOf("(");
            if (index !== -1) {
                // If parentheses are found, return the substring before the parentheses
                return key.substring(0, index).trim();
            } else {
                // If no parentheses are found, return the original key
                return key;
            }
        },
        getLabel2(key) {
            const startIndex = key.indexOf("(");
            const endIndex = key.indexOf(")");
            if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
                // If parentheses are found and they are in the correct order, extract the substring inside the parentheses
                return " " + key.substring(startIndex + 1, endIndex).trim();
            } else {
                // If no parentheses are found or they are in the wrong order, return an empty string
                return "";
            }
        },
        getLabel3(key) {
            const startIndex = key.indexOf("(");
            const endIndex = key.indexOf(")");
            if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
                // If parentheses are found and they are in the correct order, extract the substring inside the parentheses
                return key.substring(startIndex + 1, endIndex).trim();
            } else {
                // If no parentheses are found or they are in the wrong order, return an empty string
                return "";
            }
        },
        addCommas(value) {
            if (!isNaN(value) && typeof value === "number") {
                return value.toLocaleString();
            } else {
                return value;
            }
        },
    },
    async mounted() {
        if (this.myStore.info == null) {
            let user_details = localStorage.getItem("commercials_user_data");
            user_details = JSON.parse(user_details);
            console.log("user details: -> ", user_details);
            let user_id = user_details.uid;
            await axios
                .get(
                    `https://portal-litta-api.web.app/commercial/users/${user_id}/info`
                )
                .then((response) => {
                    this.myStore.info = response;
                    // console.log('endpoint_data', this.endpointData);
                })
                .catch((error) => {
                    console.log("error occured in server", error);
                    this.$emit("triggerFunction");
                });
        }

        if (this.myStore.info) {
            this.endpointData = this.myStore.info.data.data;
            this.$emit("triggerFunction");
        } else {
            this.noDataFound = true;
            this.$emit("triggerFunction");
        }
    },
};
</script>

<style scoped>
.main_text {
    font-family: poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.68px;
    text-align: left;
}

.main_text span {
    color: #06cccc;
}

.card_title {
    font-family: poppins;
    font-size: 22px;
    font-weight: 800;
    line-height: 23.68px;
    text-align: left;
    margin-bottom: 0;
}

.card_content {
    font-family: poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 31.68px;
    text-align: left;
}

.custom_card {
    border-radius: 12px;
    border: none;
}

.options_drop_down {
    background-color: #f7fafc;
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
}

.text_right {
    font-size: 17px;
    font-weight: 600;
}

.text_right a {
    font-size: 17px;
    font-weight: 600;
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
    cursor: pointer;
    text-decoration: underline;
}
</style>
