<template>
    <div class="row p-0 p-xl-5 pt-0 pt-xl-0" v-if="!noDataFound">
        <div class="col-12 col-xl-7 col-xxl-8">
            <!-- <p class="main_text ps-1">
                {{ subTitle }}
            </p> -->
            <div class="card custom_card p-3">
                <h2 class="card_title mb-3">
                    LITTA APP LIMITED
                </h2>

                <!-- <p class="mb-3">
                    Registration number: CBDU375738
                </p>
                <p class="mb-3">
                    Business name: LITTA APP LIMITED
                </p>
                <p class="mb-3">
                    Company number: 10882506 (opens in new tab)
                </p>
                <p class="mb-3">
                    Address: LITTA APP LTD, Sopers House, Sopers Road, Cuffley, EN64RY
                </p>
                <p class="mb-3">
                    Postcode: EN64RY (opens in new tab)
                </p>
                <p class="mb-3">
                    Registered as: Broker Dealer - Upper Tier
                </p>
                <p class="mb-3">
                    Applicant type: Company
                </p>
                <p class="mb-3">
                    Registration date: 15/01/2024
                </p>
                <p class="mb-3">
                    Expiry date: 22/02/2027
                </p> -->
                <table class="mt-3">
                    <tbody>
                        <tr v-for="(data,index) in endpointData" :key="index">
                            <td>
                                <p class="mb-3">
                                    {{ data.fieldName }}
                                </p>
                            </td>

                            <td class="d-flex">
                                <p class="mb-3">
                                    {{ data.value }}
                                </p>
                                <a :href="data.url" v-if="data.url" target="_blank" class="ms-2">
                                    <img src="@/assets/compliance/new_tab.png" alt="">
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- <div class="col-12 col-xl-5 col-xxl-4 d-flex p-xl-5 pt-xl-0 pt-3">
            <div class="w-auto mx-auto my-auto">
                <iframe style="border: 1px solid #dddddd; overflow: hidden" width="330" height="209" src="https://environment.data.gov.uk/public-register/waste-carriers-brokers/widget/CBDU375738" title="Widget for CBDU375738"></iframe>
            </div> -->
            <!-- <div class="my-auto w-100 me-xl-5 ms-xl-5 border_radius_10">
                <div class="bg_dark p-2">
                    <h2 class="text-white text-center ps-2 pe-2 ps-xl-5 pe-xl-5">
                        Environment Agency registered Waste Carrier, Broker or Dealer
                    </h2>
                </div>
                <div class="card pb-2">
                    <div class="w_50 pt-4 pb-5 mb-5">
                        <div class="row">
                            <div class="col-6 left_text">
                                Business Name
                            </div>
                            <div class="col-6 right_text">
                                LITTA APP LIMITED
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-6 left_text">
                                Registration Number
                            </div>
                            <div class="col-6 right_link">
                                CBU375738
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-6 left_text">
                                Company Number
                            </div>
                            <div class="col-6 right_link">
                                10882506
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-6 left_text">
                                Expiry Date
                            </div>
                            <div class="col-6 right_text">
                                22/02/2027
                            </div>
                        </div>
                    </div>
                </div>
                <img src="@/assets/Screenshot_2.png" class="w-100" alt="">
            </div> -->
        <!-- </div> -->
    </div>

    <div v-if="noDataFound" class="p-0 p-xl-5 pt-0 pt-xl-0">
        <div class="alert alert-warning">
            Oops something went wrong, we're working on fixing this
        </div>
    </div>
</template>

<script>
    import { useMyStore } from '@/store';
    import axios from 'axios';
    export default{
        data() {
            return{
                endpointData: [],
                subTitle: '',
                myStore: useMyStore(),
                noDataFound: false,
            }
        },
        async mounted() {
            // await axios.get('https://portal-litta-api.web.app/commercial/content').then(response=>{
            //     response.data.data.map(item=>{
            //         if (item.type == 'tab' && item.tag == 'litta_compliance_documents') {
            //             this.subTitle = item.subtitle;
            //         }
            //     });
            //     }).catch(error=>{
            //     console.log('error: ', error);
            // });
            if (this.myStore.company_info == null) {
                await axios.get('https://portal-litta-api.web.app/commercial/company-information').then(response=>{
                    this.myStore.company_info = response;
                    console.log('endpoint_data', this.endpointData);
                    
                }).catch(error=>{
                    this.$emit('triggerFunction');
                    console.log('error occured in server', error);
                });
            }
            if (!this.myStore.company_info) {
                this.noDataFound = true;
                this.$emit('triggerFunction');
            }
            this.endpointData = this.myStore.company_info.data.data;
            let heighest_sort = 0;
            this.myStore.company_info.data.data.map((item)=>{
                if (item.sort){
                    if (item.sort > heighest_sort){
                        heighest_sort = item.sort;
                    }
                }
            });
            this.endpointData = this.endpointData.filter((item)=>{
                if (item.active){
                    return item;
                }
            });
            this.endpointData.sort((a, b) => a.sort - b.sort);
            this.$emit('triggerFunction');
        },
    }
</script>

<style scoped>
    .main_text{
        font-family: poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.68px;
        text-align: left;
    }

    .main_text span{
        color: #06CCCC;
    }

    .card_title{
        font-family: poppins;
        font-size: 22px;
        font-weight: 800;
        line-height: 23.68px;
        text-align: left;
        margin-bottom: 0;
    }

    .card_content{
        font-family: poppins;
        font-size: 24px;
        font-weight: 600;
        line-height: 31.68px;
        text-align: left;
    }

    .custom_card{
        border-radius: 12px;
        border: none;
    }

    .options_drop_down{
        background-color: #F7FAFC;
        padding: 5px 10px;
        border-radius: 10px;
        cursor: pointer;
        border: none;
    }

    .right_container{
        /* border-bottom: 10px solid #48A049; */
    }

    .bg_dark{
        background-color: #000;
        border-bottom: 10px solid #48A049;
    }

    .w_50{
        width: 90%;
        margin: auto;
    }

    @media (min-width: 1200px) {
        .w_50{
            width: 60%;
            margin: auto;
        }
    }

    .left_text{
        font-size: 18px;
        font-weight: 900;
    }

    .right_text{
        font-size: 18px;
        font-weight: 600;
    }

    .right_link{
        font-size: 18px;
        font-weight: 600;
        color: #4B7DB4;
    }

    .border_radius_10{
        border-radius: 20px;
        overflow: hidden;
        border: 1px solid black;
    }

    .litta_title{
        font-family: poppins;
        font-size: 16px;
        font-weight: 700;
        line-height: 23.68px;
        text-align: left;

    }
</style>