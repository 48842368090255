<template>
  <LoadingOverlay :active="isLoading" :can-cancel="false" :is-full-page="true" />
  <div class="body container-fluid">
    <div class="headerr p-3 p-xl-5 pb-3 pb-xl-3">
      <CommonHeader />

      <div class="mt-5 class_css_tab_headers d-flex flex_wrap" >
        <!-- <router-link class="navbar-brand mb-3" to="/litta-jobs">
          <p class="me-3 me-lg-5">
            {{ jobs_title }}
          </p>
        </router-link>

        <router-link class="navbar-brand mb-3" to="/">
          <p class="me-3 me-lg-5 active">
            {{ metrics_title }}
          </p>
        </router-link>

        <router-link class="navbar-brand mb-3" to="/compliance-documents">
          <p class="me-3 me-lg-5">
            {{ documents_title }}
          </p>
        </router-link>

        <router-link class="navbar-brand mb-3" to="/account-info">
          <p class="me-3 me-lg-5">
            {{ info_title }}
          </p>
        </router-link> -->

        <p class="me-3 me-lg-5" :class="activeTab == 'your_metrics' ? 'active' : ''" @click="openTab('your_metrics')" v-if="metrics_permission">
          {{ metrics_title }}
        </p>
        <!-- <router-link class="navbar-brand mb-3" to="/litta-jobs"> -->
          <p class="me-3 me-lg-5" :class="activeTab == 'jobs' ? 'active' : ''" @click="openTab('jobs')" v-if="jobs_permission">
            {{ jobs_title }}
          </p>
          <p class="me-3 me-lg-5" :class="activeTab == 'info' ? 'active' : ''" @click="openTab('info')" v-if="info_permission">
            {{ info_title }}
          </p>
          <p class="me-3 me-lg-5" :class="activeTab == 'billing' ? 'active' : ''" @click="openTab('billing')" v-if="billing_permission">
            {{ payments_title }}
          </p>
        <!-- </router-link> -->

        <!-- <router-link class="navbar-brand mb-3" to="/compliance-documents"> -->
          <p class="me-3 me-lg-5" :class="activeTab == 'compliance' ? 'active' : ''" @click="openTab('compliance')" v-if="compliance_permission">
            {{ documents_title }}
          </p>
        <!-- </router-link> -->

        <!-- <router-link class="navbar-brand mb-3" to="/account-info"> -->
        <!-- </router-link> -->
      </div>
    </div>

    <div class="tab_body">
      <LittaJobs v-if="activeTab == 'jobs'" @triggerFunction="childLoaded" />
      <YourMetrics v-if="activeTab == 'your_metrics'" @triggerFunction="childLoaded" />
      <ComplicanceDocuments v-if="activeTab == 'compliance'" @triggerFunction="childLoaded" />
      <YourInfo v-if="activeTab == 'info'" @triggerFunction="childLoaded" />
      <BillingPayments v-if="activeTab == 'billing' " @triggerFunction="childLoaded" />
    </div>
  </div>
  <CommonFooter />
</template>
  
<script>
  import CommonFooter from '@/components/HeaderFooter/CommonFooter.vue';
  import CommonHeader from '@/components/HeaderFooter/CommonHeader.vue';
  import YourMetrics from '@/components/DashboardTabs/YourMetrics.vue';
  import ComplicanceDocuments from '@/components/DashboardTabs/ComplianceDocuments.vue';
  import LittaJobs from '@/components/DashboardTabs/LittaJobs.vue';
  import YourInfo from '@/components/DashboardTabs/YourInfo.vue';
  import BillingPayments from '@/components/DashboardTabs/BillingPayments.vue';
  import LoadingOverlay from 'vue3-loading-overlay';
  import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
  import { useMyStore } from '@/store';
  import axios from 'axios';
  export default {
    // Component options
    data() {
      return {
        activeTab: '',
        jobs_title: '',
        metrics_title: '',
        documents_title: '',
        info_title: '',
        payments_title: '',
        isLoading: false,
        parentFullyLoaded: false,
        myStore: useMyStore(),
        metrics_permission: false,
        jobs_permission: false,
        info_permission: false,
        billing_permission: false,
        compliance_permission: false,
      }
    },
    components: {
      YourMetrics,
      ComplicanceDocuments,
      LittaJobs,
      YourInfo,
      LoadingOverlay,
      BillingPayments,
      CommonFooter,
      CommonHeader,
    },
    methods: {
        logout() {
            // Remove user details from local storage
            localStorage.removeItem('commercials_user_data');

            // Redirect the user to the login page
            this.$router.push('/commercial/login');
        },
        handleLoading() {
          if (this.parentFullyLoaded && this.childFullyLoaded) {
            this.isLoading = false;
          }
        },
        childLoaded() {
          this.childFullyLoaded = true;
          this.handleLoading();
        },
        openTab(val){
          this.myStore.showFooter = true;
          this.isLoading = true;
          this.childFullyLoaded = false;
          this.activeTab = val;
        },
        tempAllActive(){
          this.metrics_permission = true;
          this.jobs_permission = true;
          this.info_permission = true;
          this.billing_permission = true;
          this.compliance_permission = true;
        },
    },
    async mounted() {
        this.isLoading = true;
        let user_details = localStorage.getItem('commercials_user_data');
        user_details = JSON.parse(user_details);
        console.log('user details: -> ', user_details);
        let user_id = user_details.uid;
        if (!this.myStore.valid_user){
          if (this.myStore.valid_user == null) {
            axios.get(`https://portal-litta-api.web.app/commercial/users/${user_id}`).then(response=>{
              console.log('response valid_user: ', response);
              if (response.data.data.isValidUser != true) {
                this.$router.push('/partner/metrics');
              } else {
                this.myStore.setValidUser(true);
              }
            }).catch(error=>{
              console.log('error:', error);
            });
          } else {
            this.$router.push('/partner/metrics');
          }
        }

        if (this.myStore.commercial_permissions == null) {
          await axios.get(`https://portal-litta-api.web.app/commercial/users/${user_id}`).then(response=>{
            this.myStore.commercial_permissions = response;
          }).catch(error=>{
            console.log('error', error);
          });
        }

        if (this.myStore.commercial_permissions) {
          this.myStore.commercialCompanyName = this.myStore.commercial_permissions.data.data.accountName;
        }
        this.myStore.commercial_permissions.data.data.permissions?.tabAccess.map((item)=>{
          if (item.name == 'client_information') {
            this.info_permission = true;
          }
          if (item.name == 'client_metrics') {
            this.metrics_permission = true;
          }
          if (item.name == 'litta_compliance_documents') {
            this.compliance_permission = true;
          }
          if (item.name == 'litta_jobs') {
            this.jobs_permission = true;
          }
          if (item.name == 'billing') {
            this.billing_permission = true;
          }
        });
        // this.tempAllActive();
        if (this.metrics_permission) {
          // this.activeTab = 'your_metrics';
          this.$router.push('/commercial/metrics');
        } else if (this.jobs_permission) {
          // this.activeTab = 'jobs';
          this.$router.push('/commercial/jobs');
        } else if (this.info_permission) {
          // this.activeTab = 'info';
          this.$router.push('/commercial/information');
        } else if (this.billing_permission) {
          // this.activeTab = 'billing';
          this.$router.push('/commercial/billing');
        } else if (this.compliance_permission) {
          // this.activeTab = 'compliance';
          this.$router.push('/commercial/compliance');
        }
        // switch (first_tab) {
        //   case 'info':
        //     this.activeTab = 'info';
        //     break;
        //   case 'metrics':
        //     this.activeTab = 'your_metrics';
        //     break;
        //   case 'compliance':
        //     this.activeTab = 'compliance';
        //     break;
        //   case 'jobs':
        //     this.activeTab = 'jobs';
        //     break;
        //   case 'billing':
        //     this.activeTab = 'billing';
        //     break;
        //   default:
        //     this.activeTab = 'your_metrics';
        //     break;
        // }
        this.parentFullyLoaded = false;
        this.childFullyLoaded - false;
        if (this.myStore.info_title == null) {
          await axios.get('https://portal-litta-api.web.app/commercial/content').then(response=>{
            response.data.data.map(item=>{
              if (item.type == 'tab' && item.tag == 'client_information') {
                this.info_title = item.content;
                this.myStore.setInfoTitle(this.info_title);
              }
              if (item.type == 'tab' && item.tag == 'client_metrics') {
                this.metrics_title = item.content;
                this.myStore.setMetricsTitle(this.metrics_title);
              }
              if (item.type == 'tab' && item.tag == 'litta_compliance_documents') {
                this.documents_title = item.content;
                this.myStore.setDocumentsTitle(this.documents_title);
              }
              if (item.type == 'tab' && item.tag == 'litta_jobs') {
                this.jobs_title = item.content;
                this.myStore.setJobsTitle(this.jobs_title);
              }
              if (item.type == 'tab' && item.tag == 'billing') {
                this.payments_title = item.content;
                this.myStore.setPaymentsTitle(this.payments_title);
              }
            });
          }).catch(error=>{
            console.log('error: ', error);
          });
        } else {
          this.info_title = this.myStore.info_title;
          this.metrics_title = this.myStore.metrics_title;
          this.documents_title = this.myStore.documents_title;
          this.jobs_title = this.myStore.jobs_title;
          this.payments_title = this.myStore.payments_title;
        }
        this.myStore.showFooter = true;
        this.parentFullyLoaded = true;
        this.handleLoading();
    },
  }
</script>
  
<style scoped>
  /* Component styles */
  .body{
    background-color: #F7FAFC;
    min-height: 90vh;
  }
  
  .class_css_tab_headers p{
    font-family: poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: 0.02em;
    text-align: left;
    cursor: pointer;
  }

  .class_css_tab_headers p.active{
    font-family: poppins;
    font-size: 15px;
    font-weight: 800;
    line-height: 19.6px;
    letter-spacing: 0.02em;
    text-align: left;
    border-bottom: 2px solid #06CCCC;
    padding-bottom: 5px;
  }

  .flex_wrap{
    flex-wrap: wrap;
  }

  .logout_button{
      background: #FFE7E0;
      color: #D41866;
      font-size: 15px;
      font-weight: 600;
      padding: 10px 20px;
      border-radius: 50px;
      border: none;
  }

  .company_name_col{
    align-content: center;
  }

  .company_name{
    font-family: Poppins;
    font-size: 28px;
    font-weight: 800;
    line-height: 31.68px;
    text-align: left;
    margin-bottom: 0;
  }
  .headerr{
    padding-top: 0px;
    border-top: 2px solid #06CCCC;
  }

  @media (max-width:764px) {
    .flex_wrap{
        flex-wrap: nowrap;
        overflow-x: scroll;
    }
    .flex_wrap::-webkit-scrollbar {
        display: none; /* Hide the scrollbar */
    }
  }
</style>