<template>
    <div class="p-0 p-xl-5 pt-0 pt-xl-0">
        <p>
            {{ subTitle }}
        </p>
    </div>
</template>

<script>
    import { useMyStore } from '@/store';
    export default{
        data(){
            return {
                myStore: useMyStore(),
                subTitle: '',
            }
        },
        mounted() {
            this.myStore.partner_dashboard.data.data.map((item)=>{
                if (item.type?.includes('tab') && item.tag == 'video') {
                    this.subTitle = item.subtitle;
                }
            });

            this.$emit('triggerFunction');
        },
    }
</script>

<style scoped>

</style>